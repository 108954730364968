import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { AppConfig } from 'src/config/app.config';
import { User } from 'src/app/models/user.model';
import { UserConfig } from 'src/app/models/user-config.model';
import { UserClientService } from 'src/app/services/user-client.service';
import { Observable, of, Subject, combineLatest } from 'rxjs';
import { shareReplay, tap, takeUntil, map, take } from 'rxjs/operators';
import { LoadingState } from 'src/app/utils/utils';
import { SubscriptionType } from 'src/shared/subscriptions.shared';

@Component({
  selector: 'app-client-home',
  templateUrl: './client-home.component.html',
})
export class ClientHomeComponent implements OnInit, OnDestroy {
  professionalConfig: UserConfig;

  professional$: Observable<User | null>;

  professionalSubscriptionType$: Observable<SubscriptionType>;

  menuOpen = false;

  appConfig = AppConfig;

  user: User;

  shouldDisplayFirstBox = false;

  connectLinks$: Observable<{ key: string; icon: string; url: string }[]>;

  pageLoaded: LoadingState = 'loading';

  private destroy$ = new Subject<void>();

  constructor(
    public authService: AuthService,
    private userClientService: UserClientService,
  ) {}

  ngOnInit() {
    this.pageLoaded = 'loading';

    const user$ = this.authService.user$.pipe(
      tap((user) => {
        this.user = user;
      }),
      shareReplay(1),
    );

    this.professional$ = this.userClientService.professional$.pipe(
      tap((_professional) => {}),
      shareReplay(1),
    );

    const professionalConfig$ = this.userClientService.professionalConfig$.pipe(
      tap((config) => {
        this.professionalConfig = config;
        this.updateDisplaySettings(config);
      }),
      shareReplay(1),
    );

    this.professionalSubscriptionType$ =
      this.userClientService.professionalSubscriptionType$;

    combineLatest([
      user$,
      this.professional$,
      this.professionalSubscriptionType$,
      professionalConfig$,
    ])
      .pipe(
        takeUntil(this.destroy$),
        map(([user, professional, professionalSubscriptionType, config]) => ({
          user,
          professional,
          professionalSubscriptionType,
          config,
        })),
      )
      .subscribe({
        next: (data) => {
          // All data is loaded
          this.pageLoaded = 'idle';
        },
        error: (err) => {
          console.error('Error loading data:', err);
          this.pageLoaded = 'error';
        },
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private updateDisplaySettings(config: UserConfig) {
    this.shouldDisplayFirstBox =
      (config?.reportsConfig &&
        Object.entries(config.reportsConfig).some(([key, value]) => {
          return key.includes('display') && value === true;
        })) ||
      (config?.connectLinks &&
        Object.entries(config.connectLinks).some(([key, value]) => {
          return key.includes('display') && value === true;
        }));

    this.connectLinks$ = this.userClientService.professionalConnectLinks$;
  }

  // joinRandomProfessional() {
  //   this.userClientService.joinRandomProfessional();
  // }
}
