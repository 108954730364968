/* eslint-disable quote-props */
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Client } from 'src/app/models/client.model';
import { OverrideBackButtonService } from 'src/app/services/override-back-button.service';
import { TestService } from 'src/app/services/test.service';
import {
  AvatarTest,
  AvatarArchitecture,
  getAvatarValueDefinition,
  getValueNames,
  AvatarTestValueKey,
  avatarSharedGetValueText,
  avatarSharedGetValueStatus,
  AvatarTestValuesDefinitions,
} from '../../../../../Server/functions/src/shared/avatar.shared';
import {
  getValueForReport as postureGetValueForReport,
  PostureTest,
  PostureTestFieldEnum,
} from 'src/shared/posture.outside';
import {
  SagitalTest,
  updateResults as sagitalUpdateResults,
} from 'src/app/models/sagital-test.model';
import { ReportsService } from 'src/app/services/reports.service';
import { ActionInProgressService } from 'src/app/services/action-in-progress.service';
import { ModalService } from 'src/app/services/modal.service';
import { ClientsService } from 'src/app/services/clients.service';
import { UserClientService } from 'src/app/services/user-client.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { LoadingState } from 'src/app/utils/utils';

type AvatarTab = 'frontal' | 'sagital';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent implements OnInit {
  onClose = new Subject<any>();

  // @Input()
  client: AvatarModalData;

  @Input()
  data: {
    isUserClientView: boolean;
  };

  @Input()
  isModal = true;

  @Input() openAvatarSideModal!: () => void;

  private _isUserClientView: boolean | undefined;

  @Input()
  get isUserClientView(): boolean {
    return this._isUserClientView ?? this.data?.isUserClientView ?? false;
  }
  set isUserClientView(value: boolean) {
    this._isUserClientView = value;
  }

  avatarObservable$: Observable<AvatarTest>;

  avatar: AvatarTest;

  isEmptyAvatarMock = false;

  avatarLodingState: LoadingState = 'loading';

  AvatarArchitecture = AvatarArchitecture;

  readonly AvatarTestValueKey = AvatarTestValueKey;

  clientSharedFolderUrl: string;

  getValueNames = getValueNames;

  getAvatarValueDefinition = getAvatarValueDefinition;

  avatarSharedGetValueText = avatarSharedGetValueText;

  avatarSharedGetValueStatus = avatarSharedGetValueStatus;

  loadingValues = false;

  selectedTab: AvatarTab;

  currentStatusItemHoverKey: AvatarTestValueKey = null;

  editMode = false;
  editModeSelectedKey: AvatarTestValueKey;

  generatingReport = false;

  CCMorfotipoPairs = {
    Neutro: 'Normalidad',
    Anterior: 'Anteriorizada',
    Posterior: 'Posteriorizada',
  };

  CCAsimetriaPairs = {
    Neutra: 'Normalidad',
    Izquierda: 'Inclinación',
    Derecha: 'Inclinación',
  };

  CEMorfotipoPairs = {
    Neutro: 'Normalidad',
    Anterior: 'Antepulsión',
    Posterior: 'Retropulsión',
  };

  CEInclinacionPairs = {
    Neutros: 'Neutra',
    Derecha: 'Derecha',
    Izquierda: 'Izquierda',
  };

  RDorsalPairs = {
    Normalidad: 'Normalidad',
    Hipercifosis: 'Hipercifosis',
    Hipocifosis: 'Hipocifosis',
  };

  RLumbarPairs = {
    Inversión: 'Inversión',
    Hipolordosis: 'Rectificado',
    Normalidad: 'Normalidad',
    Hiperlordosis: 'Hiperlordosis',
  };

  RSacroPairs = {
    Retroversión: 'Retroversión',
    Normalidad: 'Neutro',
    Anteversión: 'Anteversión',
  };
  MEMusloRatio = {
    Normalidad: 'Normalidad',
    'Cortedad G-I': 'Cortedad G1 Isquios',
    'Cortedad G-II': 'Cortedad G2 Isquios',
  };

  RMorfotipoPairs = {
    Neutro: 'Neutro',
    Hiperlordótico: 'Hiperlordótico',
    Hipercifótico: 'Hipercifótico',
    Rectificado: 'Rectificado',
    Cifolordótico: 'Cifolordótico',
  };

  RPelvisPairs = {
    Retroversión: 'Retroversión',
    Normalidad: 'Neutra',
    Anteversión: 'Anteversión',
  };

  constructor(
    private clients: ClientsService,
    private tests: TestService,
    private overrideBackButton: OverrideBackButtonService,
    private reportsService: ReportsService,
    private actionInProgressService: ActionInProgressService,
    private snackbarService: SnackbarService,
    private modalService: ModalService,
    private userClientService: UserClientService,
  ) {}

  ngOnInit(): void {
    if (this.isUserClientView) {
      this.userClientService.userClient$.subscribe((client) => {
        this.client = client;
        if (!this.client) {
          console.warn('No client found for user client view');
          return;
        }
        this.loadAvatar();
        this.clientSharedFolderUrl = this.client.sharedFolderUrl;
      });
      this.selectedTab = 'sagital';
    } else {
      this.clients.currentClient$.subscribe((client) => {
        this.client = client;
        this.loadAvatar();
        this.clientSharedFolderUrl = this.client.sharedFolderUrl;
      });
      this.selectedTab = 'frontal';
    }
  }

  async loadAvatar() {
    this.avatarLodingState = 'loading';
    if (this.isUserClientView) {
      this.avatar = await this.tests.getAvatarForClient(this.client.id);
      if (!this.avatar) {
        console.warn('Avatar no encontrado para cliente');
        this.avatar = await this.tests.createAvatarObject(this.client.id);
        this.isEmptyAvatarMock = true;
        this.avatarLodingState = 'idle';
        return;
      }
      // subscribe to the test document so the view is updated whith the changes
      this.avatarObservable$ = this.tests.getTest$(this.avatar.id);
      this.isEmptyAvatarMock = false;
      this.avatarObservable$.subscribe((avatar) => {
        this.avatar = avatar;
        this.isEmptyAvatarMock = false;
        this.avatarLodingState = 'idle';
      });
    } else {
      this.avatar = await this.tests.getAvatar(this.client.id);
      if (!this.avatar) {
        console.warn('Avatar no encontrado para cliente del profesional');
        this.avatar = await this.tests.createAvatarObject(this.client.id);
        this.isEmptyAvatarMock = true;
        this.avatarLodingState = 'idle';
        return;
      }
      // subscribe to the test document so the view is updated whith the changes
      this.avatarObservable$ = this.tests.getTest$(this.avatar.id);
      this.isEmptyAvatarMock = false;
      this.avatarObservable$.subscribe((avatar) => {
        // console.log('avatar on subscribe', avatar);
        this.avatar = avatar;
        this.isEmptyAvatarMock = false;
        this.avatarLodingState = 'idle';
      });
    }
  }

  async createAvatar() {
    await this.tests.createAvatar(this.client.id);
    await this.loadAvatar();
  }

  reloadPage() {
    window.location.reload();
  }

  historyBackClose() {
    this.onClose.next('');
  }

  close() {
    history.back();
  }

  enterEditMode(key = null) {
    if (this.isUserClientView) {
      return;
    }
    this.editMode = true;
    this.editModeSelectedKey = key;
    this.overrideBackButton.override(() => (this.editMode = false));
    setTimeout(() => {
      this.editModeSelectedKey = null;
    }, 3000);
  }

  exitEditMode() {
    history.back();
  }

  changeSelectedTab(selectedTabCode: AvatarTab) {
    this.selectedTab = selectedTabCode;
  }

  statusItentHoverEnter(key: AvatarTestValueKey) {
    this.currentStatusItemHoverKey = key;
  }

  statusItentHoverLeave() {
    this.currentStatusItemHoverKey = null;
  }

  setValueText(field, value) {
    if (!this.avatar.values) {
      this.avatar.values = {};
    }
    this.avatar.values[field] = value;
    this.updateTest();
  }

  setValueBinary(field, event) {
    const value = event.checked ? 1 : 0;
    if (!this.avatar.values) {
      this.avatar.values = {};
    }
    this.avatar.values[field] = value;
    this.updateTest();
  }

  setValue(field, value) {
    if (!this.avatar.values) {
      this.avatar.values = {};
    }
    this.avatar.values[field] = value;
    // console.log('field', field, 'value', value);
    this.updateTest();
  }

  getValueText(field) {
    const def = getAvatarValueDefinition(field);
    if (!this.avatar.values) {
      return '';
    }
    const result = this.avatar.values[field];
    if (!result) {
      return '';
    }
    return result;
  }

  getValueStatus(field) {
    const value = this.getValue(field);
    const def = getAvatarValueDefinition(field);
    if (def.type === 'binary') {
      return value ? 'bad' : 'neutral';
    }
    if (!def.values) {
      return 'neutral';
    }

    const found = def.values[value];
    if (!found) {
      return 'neutral';
    }
    return found.status;
  }

  getValue(field) {
    if (!this.avatar.values) {
      return -1;
    }
    const result = this.avatar.values[field];
    return result;
  }

  getValueBinary(field) {
    if (!this.avatar.values) {
      return false;
    }
    const value = this.avatar.values[field];
    const result = value === 1;
    return result;
  }

  updateTest() {
    if (this.isEmptyAvatarMock) {
      return;
    }
    this.tests.updateTest(this.avatar);
  }

  setValueByText(
    key: AvatarTestValueKey,
    valueText: string,
    pairs: any,
    sameValues = false,
  ) {
    let translated = '';
    try {
      translated = pairs[valueText];
    } catch (error) {
      if (!sameValues) {
        // console.log(error);
        return;
      }
      translated = valueText;
    }
    const def = AvatarTestValuesDefinitions[key];
    const foundIndex = def.values.findIndex((v) => v.name === translated);
    if (foundIndex === -1) {
      // throw new Error('Cannot find the value');
    }
    if (!this.avatar.values) {
      this.avatar.values = {};
    }
    this.avatar.values[key] = foundIndex;
  }

  async loadValuesFromTests() {
    this.loadingValues = true;
    // console.log(this.avatar);
    const tests = await this.tests.findAvatarRelatedTests(this.client.id);
    if (tests.posture) {
      this.setValueByText(
        AvatarTestValueKey.CabezaCuelloMorfotipo,
        postureGetValueForReport(
          PostureTestFieldEnum.PSLobulo,
          tests.posture as PostureTest,
        ),
        this.CCMorfotipoPairs,
      );
      this.setValueByText(
        AvatarTestValueKey.CabezaCuelloAsimetria,
        postureGetValueForReport(
          PostureTestFieldEnum.PFCabeza,
          tests.posture as PostureTest,
        ),
        this.CCAsimetriaPairs,
      );
      this.setValueByText(
        AvatarTestValueKey.CinturaEscapularMorfotipo,
        postureGetValueForReport(
          PostureTestFieldEnum.PSArticulacionHombro,
          tests.posture as PostureTest,
        ),
        this.CEMorfotipoPairs,
      );
      this.setValueByText(
        AvatarTestValueKey.CEInclinacion,
        postureGetValueForReport(
          PostureTestFieldEnum.PFHombros,
          tests.posture as PostureTest,
        ),
        this.CEInclinacionPairs,
      );
      this.setValueByText(
        AvatarTestValueKey.RMorfotipo,
        postureGetValueForReport(
          PostureTestFieldEnum.AvatarMR,
          tests.posture as PostureTest,
        ),
        this.RMorfotipoPairs,
      );

      this.setValueByText(
        AvatarTestValueKey.MEMorfotipo,
        postureGetValueForReport(
          PostureTestFieldEnum.AvatarMMI,
          tests.posture as PostureTest,
        ),
        null,
        true,
      );
      this.setValueByText(
        AvatarTestValueKey.PTMorfotipo,
        postureGetValueForReport(
          PostureTestFieldEnum.AvatarMPT,
          tests.posture as PostureTest,
        ),
        null,
        true,
      );

      this.setValueByText(
        AvatarTestValueKey.RPostureMorfotipoPostural,
        postureGetValueForReport(
          PostureTestFieldEnum.PSMorfotipoPostural,
          tests.posture as PostureTest,
        ),
        null,
        true,
      );
    }
    // console.log('-------------');

    if (tests.sagital) {
      sagitalUpdateResults(tests.sagital);
      const sagital: SagitalTest = tests.sagital;
      // console.log('-------------');
      // console.log(tests.sagital);
      if (sagital.bipedestacionResults.dorsal) {
        this.setValueByText(
          AvatarTestValueKey.RDorsal,
          sagital.bipedestacionResults.dorsal.text,
          this.RDorsalPairs,
        );
      }
      if (sagital.bipedestacionResults.lumbar) {
        this.setValueByText(
          AvatarTestValueKey.RLumbar,
          sagital.bipedestacionResults.lumbar.text,
          this.RLumbarPairs,
        );
      }
      if (sagital.bipedestacionResults.pelvis) {
        this.setValueByText(
          AvatarTestValueKey.RSacro,
          sagital.bipedestacionResults.pelvis.text,
          this.RSacroPairs,
        );
      }
      if (sagital.sedentacionResults.pelvic) {
        this.setValueByText(
          AvatarTestValueKey.CPMorfotipo,
          sagital.sedentacionResults.pelvic.text,
          this.RPelvisPairs,
        );
      }
      if (sagital.flexMaxTroncoResults.pelvic) {
        this.setValueByText(
          AvatarTestValueKey.MEMusloRatio,
          sagital.flexMaxTroncoResults.pelvic.text,
          this.MEMusloRatio,
        );
      }

      if (sagital.apex) {
        this.setValueByText(
          AvatarTestValueKey.RApex,
          'Presenta Ápex',
          null,
          true,
        );
      } else {
        this.setValueByText(
          AvatarTestValueKey.RApex,
          'No presenta',
          null,
          true,
        );
      }
      // console.log('sagital escoliosis', sagital.escoliosis);
      if (sagital.escoliosis) {
        this.setValueByText(
          AvatarTestValueKey.REscoliosis,
          sagital.escoliosis,
          null,
          true,
        );
      } else {
        this.setValueByText(
          AvatarTestValueKey.REscoliosis,
          'No presenta',
          null,
          true,
        );
      }
    }
    this.updateTest();
    this.loadingValues = false;
  }

  getComments(propName: string) {
    return this.avatar[propName];
  }

  setComments(propName: string, value: string) {
    this.avatar[propName] = value;
    this.updateTest();
  }

  onCommentsChanged(propName: string, event) {
    if (this.isUserClientView) {
      return;
    }
    const value = event.target.value;
    this.setComments(propName, value);
    this.updateTest();
  }

  async editClient(data: any) {
    await this.modalService.openModal('create-edit-client', data);
  }

  setMultimediaLink(value: any) {
    value = value.trim();
    this.avatar.multimediaLink = value;
    this.updateTest();
  }

  setLinks(value: any) {
    this.avatar.links = value;
    this.updateTest();
  }
  getLinks() {
    return this.avatar.links;
  }

  async generateReport() {
    if (this.generatingReport) {
      return;
    }
    this.generatingReport = true;
    this.actionInProgressService.component.show('Generando informe...');
    try {
      await this.reportsService.generateReport([this.avatar]);
    } catch (e) {
      console.error(e);
      this.snackbarService.error('Error generando informe');
    } finally {
      this.actionInProgressService.component.hide();
      this.generatingReport = false;
    }
  }
}

export type AvatarModalData = Client;
