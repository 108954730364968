<ng-container *ngIf="avatarLodingState === 'loading'">
  <div class="flex h-full items-center justify-center">
    <i class="fas fa-spinner fa-spin mr-4 text-4xl text-intecc2"></i>
    <p>Cargando avatar...</p>
  </div>
</ng-container>
<ng-container *ngIf="avatarLodingState === 'idle'">
  <ng-container *ngIf="avatar">
    <div
      *ngIf="!editMode"
      class="h-full flex flex-col {{
        isModal
          ? 'pb-5 bg-intecc text-white'
          : 'pb-0 bg-background text-inteccDark'
      }}"
    >
      <div
        *ngIf="isModal"
        class="flex h-14 items-center justify-between bg-inteccDark p-4 text-2xl"
      >
        <p class="font-bold">Avatar Valoración</p>
        <div class="flex items-center">
          <a
            *ngIf="!isUserClientView"
            href="https://manual.intecc.org/es/article/protocolo-vaf-rom"
            target="_blank"
            rel="noopener noreferrer"
            class="mr-5 cursor-pointer text-xl"
          >
            <i class="fas fa-graduation-cap text-white"></i>
          </a>
          <div class="cursor-pointer" (click)="close()">
            <i class="fas fa-times"></i>
          </div>
        </div>
      </div>
      <div
        class="overflow-x-hidden"
        [class.overflow-y-auto]="isModal"
        [class.overflow-y-hidden]="!isModal"
      >
        <div class="relative mx-auto max-w-md pb-8">
          <!-- Main content of the avatar (image, status items, comments) -->
          <div class="content">
            <div
              *ngIf="isModal && !isUserClientView"
              (click)="loadValuesFromTests()"
              class="mx-auto mb-4 mt-8 flex w-full max-w-md items-center justify-center rounded-lg bg-inteccDark px-4 py-3 font-semibold"
            >
              <i
                class="fas fa-redo-alt mr-2 {{
                  loadingValues ? 'fa-spin' : ''
                }}"
              ></i>
              <div class="">Cargar desde las valoraciones</div>
            </div>
          </div>
          <div
            *ngIf="selectedTab === 'frontal'"
            style="height: 500px"
            class="relative mx-auto max-w-md"
          >
            <div
              style="width: 260px"
              class="absolute right-0 top-0 flex h-full w-4/6 translate-x-[20px] flex-col justify-center"
            >
              <div style="height: 480px" class="relative w-full">
                <img
                  class="h-full w-full object-contain"
                  src="../../../assets/images/human-back.png"
                  alt=""
                />
                <app-avatar-status-indicator
                  class="absolute right-0 top-0 h-full w-full"
                  [currentHoverKey]="currentStatusItemHoverKey"
                  [xy]="[51, 14]"
                  [key]="AvatarTestValueKey.CabezaCuelloMorfotipo"
                  [status]="
                    avatarSharedGetValueStatus(
                      avatar,
                      AvatarTestValueKey.CabezaCuelloMorfotipo
                    )
                  "
                ></app-avatar-status-indicator>
                <app-avatar-status-indicator
                  class="absolute right-0 top-0 h-full w-full"
                  [currentHoverKey]="currentStatusItemHoverKey"
                  [xy]="[41, 25]"
                  [key]="AvatarTestValueKey.CinturaEscapularMorfotipo"
                  [status]="
                    avatarSharedGetValueStatus(
                      avatar,
                      AvatarTestValueKey.CinturaEscapularMorfotipo
                    )
                  "
                ></app-avatar-status-indicator>
                <app-avatar-status-indicator
                  class="absolute right-0 top-0 h-full w-full"
                  [currentHoverKey]="currentStatusItemHoverKey"
                  [xy]="[46, 44]"
                  [key]="AvatarTestValueKey.CPMorfotipo"
                  [status]="
                    avatarSharedGetValueStatus(
                      avatar,
                      AvatarTestValueKey.CPMorfotipo
                    )
                  "
                ></app-avatar-status-indicator>
                <app-avatar-status-indicator
                  class="absolute right-0 top-0 h-full w-full"
                  [currentHoverKey]="currentStatusItemHoverKey"
                  [xy]="[51, 32]"
                  [key]="AvatarTestValueKey.RMorfotipo"
                  [status]="
                    avatarSharedGetValueStatus(
                      avatar,
                      AvatarTestValueKey.RMorfotipo
                    )
                  "
                ></app-avatar-status-indicator>
                <app-avatar-status-indicator
                  class="absolute right-0 top-0 h-full w-full"
                  [currentHoverKey]="currentStatusItemHoverKey"
                  [xy]="[41, 72]"
                  [key]="AvatarTestValueKey.MEMorfotipo"
                  [status]="
                    avatarSharedGetValueStatus(
                      avatar,
                      AvatarTestValueKey.MEMorfotipo
                    )
                  "
                ></app-avatar-status-indicator>
                <app-avatar-status-indicator
                  class="absolute right-0 top-0 h-full w-full"
                  [currentHoverKey]="currentStatusItemHoverKey"
                  [xy]="[40, 91]"
                  [key]="AvatarTestValueKey.PTMorfotipo"
                  [status]="
                    avatarSharedGetValueStatus(
                      avatar,
                      AvatarTestValueKey.PTMorfotipo
                    )
                  "
                ></app-avatar-status-indicator>
              </div>
            </div>
            <div
              class="absolute left-0 top-0 h-full w-full sm:pl-10"
              [class.pl-10]="isModal"
              [class.pl-[25px]]="!isUserClientView"
            >
              <div class="flex h-full flex-col justify-center">
                <app-avatar-status-item
                  (click)="
                    enterEditMode(AvatarTestValueKey.CabezaCuelloMorfotipo)
                  "
                  (mouseenter)="
                    statusItentHoverEnter(
                      AvatarTestValueKey.CabezaCuelloMorfotipo
                    )
                  "
                  (mouseleave)="statusItentHoverLeave()"
                  [title]="'Cabeza'"
                  [value]="
                    avatarSharedGetValueText(
                      avatar,
                      AvatarTestValueKey.CabezaCuelloMorfotipo
                    )
                  "
                  [status]="
                    avatarSharedGetValueStatus(
                      avatar,
                      AvatarTestValueKey.CabezaCuelloMorfotipo
                    )
                  "
                ></app-avatar-status-item>
                <div class="mb-4"></div>
                <app-avatar-status-item
                  (click)="
                    enterEditMode(AvatarTestValueKey.CinturaEscapularMorfotipo)
                  "
                  (mouseenter)="
                    statusItentHoverEnter(
                      AvatarTestValueKey.CinturaEscapularMorfotipo
                    )
                  "
                  (mouseleave)="statusItentHoverLeave()"
                  [title]="'Cintura Escapular'"
                  [value]="
                    avatarSharedGetValueText(
                      avatar,
                      AvatarTestValueKey.CinturaEscapularMorfotipo
                    )
                  "
                  [status]="
                    avatarSharedGetValueStatus(
                      avatar,
                      AvatarTestValueKey.CinturaEscapularMorfotipo
                    )
                  "
                ></app-avatar-status-item>
                <div class="mb-4"></div>
                <app-avatar-status-item
                  (click)="enterEditMode(AvatarTestValueKey.RMorfotipo)"
                  (mouseenter)="
                    statusItentHoverEnter(AvatarTestValueKey.RMorfotipo)
                  "
                  (mouseleave)="statusItentHoverLeave()"
                  [title]="'Raquis'"
                  [value]="
                    avatarSharedGetValueText(
                      avatar,
                      AvatarTestValueKey.RMorfotipo
                    )
                  "
                  [status]="
                    avatarSharedGetValueStatus(
                      avatar,
                      AvatarTestValueKey.RMorfotipo
                    )
                  "
                ></app-avatar-status-item>
                <div class="mb-4"></div>
                <app-avatar-status-item
                  (click)="enterEditMode(AvatarTestValueKey.CPMorfotipo)"
                  (mouseenter)="
                    statusItentHoverEnter(AvatarTestValueKey.CPMorfotipo)
                  "
                  (mouseleave)="statusItentHoverLeave()"
                  [title]="'Cintura Pélvica'"
                  [value]="
                    avatarSharedGetValueText(
                      avatar,
                      AvatarTestValueKey.CPMorfotipo
                    )
                  "
                  [status]="
                    avatarSharedGetValueStatus(
                      avatar,
                      AvatarTestValueKey.CPMorfotipo
                    )
                  "
                ></app-avatar-status-item>
                <div class="mb-4"></div>
                <app-avatar-status-item
                  (click)="enterEditMode(AvatarTestValueKey.MEMorfotipo)"
                  (mouseenter)="
                    statusItentHoverEnter(AvatarTestValueKey.MEMorfotipo)
                  "
                  (mouseleave)="statusItentHoverLeave()"
                  [title]="'Miembro Inferior'"
                  [value]="
                    avatarSharedGetValueText(
                      avatar,
                      AvatarTestValueKey.MEMorfotipo
                    )
                  "
                  [status]="
                    avatarSharedGetValueStatus(
                      avatar,
                      AvatarTestValueKey.MEMorfotipo
                    )
                  "
                ></app-avatar-status-item>
                <div class="mb-4"></div>
                <app-avatar-status-item
                  (click)="enterEditMode(AvatarTestValueKey.PTMorfotipo)"
                  (mouseenter)="
                    statusItentHoverEnter(AvatarTestValueKey.PTMorfotipo)
                  "
                  (mouseleave)="statusItentHoverLeave()"
                  [title]="'Tobillo y Pie'"
                  [value]="
                    avatarSharedGetValueText(
                      avatar,
                      AvatarTestValueKey.PTMorfotipo
                    )
                  "
                  [status]="
                    avatarSharedGetValueStatus(
                      avatar,
                      AvatarTestValueKey.PTMorfotipo
                    )
                  "
                ></app-avatar-status-item>
              </div>
            </div>
            <div
              *ngIf="!isModal"
              (click)="openAvatarSideModal()"
              class="absolute h-full w-full cursor-pointer bg-transparent"
            ></div>
          </div>
          <div
            *ngIf="selectedTab === 'frontal' && isModal"
            class="content px-8"
          >
            <p class="mb-2 font-bold">Comentarios del plano frontal</p>
            <textarea
              class="mb-4 w-full rounded-lg border-2 border-neutral-200 bg-intecc px-2 py-1"
              #commentsTextArea
              name="comments"
              cols="30"
              rows="5"
              [readonly]="isUserClientView"
              [value]="
                getComments('frontComments') ? getComments('frontComments') : ''
              "
              (change)="onCommentsChanged('frontComments', $event)"
            ></textarea>
          </div>
          <div
            *ngIf="selectedTab === 'sagital'"
            style="height: 500px"
            class="relative mx-auto max-w-md"
          >
            <div
              style="width: 260px"
              class="absolute right-0 top-0 flex h-full w-4/6 flex-col justify-center"
            >
              <div style="height: 480px" class="relative w-full">
                <img
                  class="h-full w-full object-contain"
                  src="../../../assets/images/human-side.png"
                  alt=""
                />
                <app-avatar-status-indicator
                  class="absolute right-0 top-0 h-full w-full"
                  [currentHoverKey]="currentStatusItemHoverKey"
                  [xy]="[39, 24]"
                  [key]="AvatarTestValueKey.RDorsal"
                  [status]="
                    avatarSharedGetValueStatus(
                      avatar,
                      AvatarTestValueKey.RDorsal
                    )
                  "
                ></app-avatar-status-indicator>
                <app-avatar-status-indicator
                  class="absolute right-0 top-0 h-full w-full"
                  [currentHoverKey]="currentStatusItemHoverKey"
                  [xy]="[46, 38]"
                  [key]="AvatarTestValueKey.RLumbar"
                  [status]="
                    avatarSharedGetValueStatus(
                      avatar,
                      AvatarTestValueKey.RLumbar
                    )
                  "
                ></app-avatar-status-indicator>
                <app-avatar-status-indicator
                  class="absolute right-0 top-0 h-full w-full"
                  [currentHoverKey]="currentStatusItemHoverKey"
                  [xy]="[46, 38]"
                  [key]="AvatarTestValueKey.RPatronLumbar"
                  [status]="
                    avatarSharedGetValueStatus(
                      avatar,
                      AvatarTestValueKey.RPatronLumbar
                    )
                  "
                ></app-avatar-status-indicator>
                <app-avatar-status-indicator
                  class="absolute right-0 top-0 h-full w-full"
                  [currentHoverKey]="currentStatusItemHoverKey"
                  [xy]="[43, 45]"
                  [key]="AvatarTestValueKey.RSacro"
                  [status]="
                    avatarSharedGetValueStatus(
                      avatar,
                      AvatarTestValueKey.RSacro
                    )
                  "
                ></app-avatar-status-indicator>
                <app-avatar-status-indicator
                  class="absolute right-0 top-0 h-full w-full"
                  [currentHoverKey]="currentStatusItemHoverKey"
                  [xy]="[43, 45]"
                  [key]="AvatarTestValueKey.CPMorfotipo"
                  [status]="
                    avatarSharedGetValueStatus(
                      avatar,
                      AvatarTestValueKey.CPMorfotipo
                    )
                  "
                ></app-avatar-status-indicator>
                <app-avatar-status-indicator
                  class="absolute right-0 top-0 h-full w-full"
                  [currentHoverKey]="currentStatusItemHoverKey"
                  [xy]="[48, 59]"
                  [key]="AvatarTestValueKey.MEMusloRatio"
                  [status]="
                    avatarSharedGetValueStatus(
                      avatar,
                      AvatarTestValueKey.MEMusloRatio
                    )
                  "
                ></app-avatar-status-indicator>
              </div>
            </div>
            <div class="absolute left-0 top-0 h-full w-full">
              <div
                class="flex h-full flex-col justify-center sm:pl-10"
                [class.pl-10]="isModal"
                [class.pl-[25px]]="!isUserClientView"
              >
                <app-avatar-status-item
                  (click)="enterEditMode(AvatarTestValueKey.REscoliosis)"
                  (mouseenter)="
                    statusItentHoverEnter(AvatarTestValueKey.REscoliosis)
                  "
                  (mouseleave)="statusItentHoverLeave()"
                  [title]="'Escoliosis'"
                  [value]="
                    avatarSharedGetValueText(
                      avatar,
                      AvatarTestValueKey.REscoliosis
                    )
                  "
                  [status]="
                    avatarSharedGetValueStatus(
                      avatar,
                      AvatarTestValueKey.REscoliosis
                    )
                  "
                ></app-avatar-status-item>
                <div class="mb-4"></div>
                <app-avatar-status-item
                  (click)="enterEditMode(AvatarTestValueKey.RDorsal)"
                  (mouseenter)="
                    statusItentHoverEnter(AvatarTestValueKey.RDorsal)
                  "
                  (mouseleave)="statusItentHoverLeave()"
                  [title]="'Dorsal'"
                  [value]="
                    avatarSharedGetValueText(avatar, AvatarTestValueKey.RDorsal)
                  "
                  [status]="
                    avatarSharedGetValueStatus(
                      avatar,
                      AvatarTestValueKey.RDorsal
                    )
                  "
                ></app-avatar-status-item>
                <div class="mb-4"></div>
                <app-avatar-status-item
                  (click)="enterEditMode(AvatarTestValueKey.RLumbar)"
                  (mouseenter)="
                    statusItentHoverEnter(AvatarTestValueKey.RLumbar)
                  "
                  (mouseleave)="statusItentHoverLeave()"
                  [title]="'Lumbar'"
                  [value]="
                    avatarSharedGetValueText(avatar, AvatarTestValueKey.RLumbar)
                  "
                  [status]="
                    avatarSharedGetValueStatus(
                      avatar,
                      AvatarTestValueKey.RLumbar
                    )
                  "
                ></app-avatar-status-item>
                <div class="mb-4"></div>
                <app-avatar-status-item
                  (click)="enterEditMode(AvatarTestValueKey.CPMorfotipo)"
                  (mouseenter)="
                    statusItentHoverEnter(AvatarTestValueKey.CPMorfotipo)
                  "
                  (mouseleave)="statusItentHoverLeave()"
                  [title]="'Pelvis'"
                  [value]="
                    avatarSharedGetValueText(
                      avatar,
                      AvatarTestValueKey.CPMorfotipo
                    )
                  "
                  [status]="
                    avatarSharedGetValueStatus(
                      avatar,
                      AvatarTestValueKey.CPMorfotipo
                    )
                  "
                ></app-avatar-status-item>
                <div class="mb-4"></div>
                <app-avatar-status-item
                  (click)="enterEditMode(AvatarTestValueKey.RSacro)"
                  (mouseenter)="
                    statusItentHoverEnter(AvatarTestValueKey.RSacro)
                  "
                  (mouseleave)="statusItentHoverLeave()"
                  [title]="'Sacro'"
                  [value]="
                    avatarSharedGetValueText(avatar, AvatarTestValueKey.RSacro)
                  "
                  [status]="
                    avatarSharedGetValueStatus(
                      avatar,
                      AvatarTestValueKey.RSacro
                    )
                  "
                ></app-avatar-status-item>
                <div class="mb-4"></div>
                <app-avatar-status-item
                  (click)="enterEditMode(AvatarTestValueKey.MEMusloRatio)"
                  (mouseenter)="
                    statusItentHoverEnter(AvatarTestValueKey.MEMusloRatio)
                  "
                  (mouseleave)="statusItentHoverLeave()"
                  [title]="'Isquiosurales'"
                  [value]="
                    avatarSharedGetValueText(
                      avatar,
                      AvatarTestValueKey.MEMusloRatio
                    )
                  "
                  [status]="
                    avatarSharedGetValueStatus(
                      avatar,
                      AvatarTestValueKey.MEMusloRatio
                    )
                  "
                ></app-avatar-status-item>
                <!-- <div class="mb-4"></div>
                    <app-avatar-status-item (click)="enterEditMode(AvatarTestValueKey.RPatronLumbar)" (mouseenter)="statusItentHoverEnter(AvatarTestValueKey.RPatronLumbar)" (mouseleave)="statusItentHoverLeave()" [title]="'Patrón Lumbar'" [value]="avatarSharedGetValueText(avatar, AvatarTestValueKey.RPatronLumbar)" [status]="avatarSharedGetValueStatus(avatar, AvatarTestValueKey.RPatronLumbar)"></app-avatar-status-item> -->
              </div>
            </div>
            <div
              *ngIf="!isModal"
              (click)="openAvatarSideModal()"
              class="absolute h-full w-full cursor-pointer bg-transparent"
            ></div>
          </div>
          <div
            *ngIf="selectedTab === 'sagital' && isModal"
            class="content px-8"
          >
            <p class="mb-2 font-bold">Comentarios del plano sagital</p>
            <textarea
              class="mb-4 w-full rounded-lg border-2 border-neutral-200 bg-intecc px-2 py-1"
              #commentsTextArea
              name="comments"
              cols="30"
              rows="5"
              [readonly]="isUserClientView"
              [value]="
                getComments('sagComments') ? getComments('sagComments') : ''
              "
              (change)="onCommentsChanged('sagComments', $event)"
            ></textarea>
          </div>
          <div *ngIf="isModal" class="content">
            <div>
              <div>
                <p class="mb-2 font-bold">Recomendaciones</p>
                <textarea
                  class="mb-4 w-full rounded-lg border-2 border-neutral-200 bg-intecc px-2 py-1"
                  #commentsTextArea
                  name="comments"
                  cols="30"
                  rows="5"
                  [readonly]="isUserClientView"
                  [value]="
                    getComments('recomendations')
                      ? getComments('recomendations')
                      : ''
                  "
                  (change)="onCommentsChanged('recomendations', $event)"
                ></textarea>
              </div>
              <div class="w-full">
                <p class="mb-1 mt-7 text-lg font-semibold">
                  Enlaces multimedia
                </p>
                <ng-container *ngIf="!isUserClientView">
                  <p>
                    Accede a la carpeta “Documentos” e inserta la URL de los
                    archivos multimedia.
                  </p>
                  <button
                    *ngIf="!clientSharedFolderUrl"
                    (click)="this.editClient({ client: client })"
                    class="mt-2 font-semibold text-white underline"
                  >
                    Añade la carpeta "Documentos"
                  </button>
                  <a
                    *ngIf="clientSharedFolderUrl"
                    class="mt-2 inline-block font-semibold text-white underline"
                    href="{{ clientSharedFolderUrl }}"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Ir a carpeta "Documentos" del cliente</a
                  >
                  <input
                    class="w-full rounded-lg border-2 border-white bg-intecc"
                    name="multimediaLink"
                    [readonly]="isUserClientView"
                    [ngModel]="
                      this.avatar.multimediaLink
                        ? this.avatar.multimediaLink
                        : ''
                    "
                    (ngModelChange)="this.setMultimediaLink($event)"
                  />
                </ng-container>
                <ng-container *ngIf="isUserClientView">
                  <a
                    *ngIf="this.avatar.multimediaLink"
                    href="{{ this.avatar.multimediaLink }}"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="break-all text-white hover:underline"
                  >
                    Ver archivos multimedia
                  </a>
                  <p *ngIf="!this.avatar.multimediaLink">
                    No hay enlace multimedia disponible
                  </p>
                </ng-container>
                <p class="mb-1 mt-7 text-lg font-semibold">
                  Enlaces opcionales
                </p>
                <p *ngIf="!isUserClientView">
                  Añade enlaces opcionales a los informes (webs, videos,
                  lecturas, etc).
                </p>
                <app-test-report-links
                  [isUserClientView]="isUserClientView"
                  [theme]="'avatar'"
                  [links]="avatar.links"
                  (changeEvent)="this.setLinks($event)"
                ></app-test-report-links>
              </div>
              <div class="">
                <button
                  (click)="generateReport()"
                  target="_blank"
                  href="https://plataforma.intecc.org/courses/INICIACION-valoracion-anatomica-y-funcional"
                  class="mt-4 flex w-full flex-row items-center justify-center overflow-hidden rounded-xl bg-inteccDark px-6 py-3 font-bold text-white transition-all hover:bg-inteccDark/80"
                >
                  <i class="fas fa-file-pdf mr-2"></i>
                  <span *ngIf="!generatingReport">Generar Informe</span>
                  <span *ngIf="generatingReport">Generando...</span>
                </button>
              </div>
            </div>
          </div>
          <!-- overlay to create new avatar or refresh if not avatar found -->
          <div
            *ngIf="isEmptyAvatarMock && !isUserClientView && !isModal"
            class="absolute left-1/2 top-1/2 flex -translate-x-1/2 -translate-y-1/2"
          >
            <div
              class="flex w-80 flex-col items-center gap-6 rounded-lg bg-white/90 p-4 text-center"
            >
              <div class="flex flex-col items-center gap-2">
                <p class="text-xl font-semibold">
                  No se ha encontrado un avatar
                </p>
                <p class="max-w-md text-gray-600">
                  Si crees que es un error, refresca la página. Si no, crea un
                  nuevo avatar.
                </p>
              </div>
              <div class="flex flex-col gap-3">
                <button
                  (click)="createAvatar()"
                  class="flex items-center justify-center gap-2 rounded-lg bg-intecc px-6 py-3 font-semibold text-white transition-all hover:bg-intecc/90"
                >
                  <i class="fas fa-plus-circle"></i>
                  <span>Crear nuevo avatar</span>
                </button>
                <button
                  (click)="reloadPage()"
                  class="flex items-center justify-center gap-2 rounded-lg border border-gray-300 px-6 py-3 font-semibold text-gray-700 transition-all hover:bg-gray-50"
                >
                  <i class="fas fa-sync-alt"></i>
                  <span>Recargar la página</span>
                </button>
              </div>
            </div>
          </div>
          <!-- end overlay to create new avatar or refresh if not avatar found -->
          <!-- end of the main content of the avatar (image, status items, comments) -->
        </div>
      </div>
      <div class="mt-4 w-full" [class.px-[25px]]="isModal || !isUserClientView">
        <div class="mx-auto w-full max-w-md">
          <app-two-tab-button
            [buttonATitle]="'Columna'"
            [buttonACode]="'sagital'"
            [buttonBTitle]="'Postura'"
            [buttonBCode]="'frontal'"
            [selectedButtonCode]="selectedTab"
            (buttonSelected)="changeSelectedTab($event)"
            [isModal]="isModal"
          ></app-two-tab-button>
          <!-- <div
        *ngIf="!isModal && !isUserClientView"
        (click)="loadValuesFromTests()"
        class="mt-4 flex cursor-pointer items-center justify-center rounded-full py-1 transition-all duration-200 hover:text-white {{
          isModal
            ? 'bg-black/20 hover:bg-black/80'
            : 'bg-transparent hover:bg-inteccDark'
        }}"
      >
        <i
          class="fas fa-redo-alt mr-2 {{ loadingValues ? 'fa-spin' : '' }}"
        ></i>
        <div class="">Cargar desde las valoraciones</div>
      </div> -->
        </div>
      </div>
    </div>
    <div *ngIf="editMode" class="h-full bg-intecc text-white">
      <div
        class="flex h-14 items-center justify-between bg-inteccDark p-4 text-2xl"
      >
        <!-- <div>
            <i class="fas fa-user"></i>
        </div> -->
        <p class="font-bold">Editar Avatar</p>
        <div class="cursor-pointer" (click)="exitEditMode()">
          <i class="fas fas fa-chevron-circle-left"></i>
        </div>
      </div>
      <div
        style="height: calc(100% - 56px)"
        class="custom-scroll-2 h-full overflow-y-auto px-8 py-5"
      >
        <div
          (click)="loadValuesFromTests()"
          class="mb-4 flex cursor-pointer items-center justify-center rounded-full bg-black/20 py-1 transition-all duration-200 hover:bg-black/80"
        >
          <i
            class="fas fa-redo-alt mr-2 {{ loadingValues ? 'fa-spin' : '' }}"
          ></i>
          <div class="">Cargar desde las valoraciones</div>
        </div>
        <p class="my-4">Marcar las limitaciones</p>
        <div *ngFor="let item of AvatarArchitecture" class="">
          <p class="rounded bg-black/20 p-2 text-center text-xl font-bold">
            {{ item.title }}
          </p>
          <div class="">
            <div class="mb-2"></div>
            <div *ngFor="let value of item.values; let i = index">
              <div
                *ngIf="getAvatarValueDefinition(value); let valueDefinition"
                class="transition-all duration-1000 {{
                  value === editModeSelectedKey
                    ? 'text-amber-400 bg-amber-400/20'
                    : ''
                }} grid grid-cols-8 items-center"
              >
                <ng-container *ngIf="valueDefinition.type === 'select'">
                  <p
                    class="font-bold col-span-2 {{
                      i === 0 ? 'underline' : ''
                    }}"
                  >
                    {{ valueDefinition.title }}
                  </p>
                  <app-test-field-dropdown
                    class="col-span-5"
                    [openToTop]="false"
                    [theme]="'avatar'"
                    [mode]="valueDefinition.type"
                    [options]="getValueNames(valueDefinition.values)"
                    [selectedIndex]="getValue(value)"
                    [text]="getValueText(value)"
                    (selectedItemChanged)="setValue(value, $event)"
                    (textChanged)="setValueText(value, $event)"
                  ></app-test-field-dropdown>
                  <app-avatar-field-status
                    class="justify-self-center"
                    [status]="getValueStatus(value)"
                  ></app-avatar-field-status>
                </ng-container>
                <ng-container *ngIf="valueDefinition.type === 'binary'">
                  <p class="col-span-2 font-bold">
                    {{
                      valueDefinition.preTitle ? valueDefinition.preTitle : ''
                    }}
                  </p>
                  <div class="col-span-5 flex justify-between">
                    <p class="px-3 py-2">{{ valueDefinition.title }}</p>
                    <mat-slide-toggle
                      class="self-center"
                      [color]="'warn'"
                      [checked]="getValueBinary(value)"
                      (change)="setValueBinary(value, $event)"
                    ></mat-slide-toggle>
                  </div>
                  <app-avatar-field-status
                    class="justify-self-center"
                    [status]="getValueStatus(value)"
                  ></app-avatar-field-status>
                </ng-container>
              </div>
            </div>

            <div class="mb-8"></div>
          </div>
        </div>
        <div>
          <div>
            <p class="mb-2 font-bold">Recomendaciones</p>
            <textarea
              class="mb-4 w-full rounded-lg border-2 border-neutral-200 bg-intecc px-2 py-1"
              #commentsTextArea
              name="comments"
              cols="30"
              rows="5"
              [value]="
                getComments('recomendations')
                  ? getComments('recomendations')
                  : ''
              "
              (change)="onCommentsChanged('recomendations', $event)"
            ></textarea>
          </div>
          <div class="w-full">
            <p class="mb-1 mt-7 text-lg font-semibold">Enlaces multimedia</p>
            <p>
              Accede a la carpeta “Documentos” e inserta la URL de los archivos
              multimedia.
            </p>
            <button
              *ngIf="!clientSharedFolderUrl"
              (click)="this.editClient({ client: client })"
              class="mt-2 font-semibold text-white underline"
            >
              Añade la carpeta "Documentos"
            </button>
            <a
              *ngIf="clientSharedFolderUrl"
              class="mt-2 inline-block font-semibold text-white underline"
              href="{{ clientSharedFolderUrl }}"
              target="_blank"
              rel="noopener noreferrer"
              >Ir a carpeta "Documentos" del cliente</a
            >
            <input
              class="w-full rounded-lg border-2 border-white bg-intecc"
              name="multimediaLink"
              [ngModel]="
                this.avatar.multimediaLink ? this.avatar.multimediaLink : ''
              "
              (ngModelChange)="this.setMultimediaLink($event)"
            />
            <p class="mb-1 mt-7 text-lg font-semibold">Enlaces opcionales</p>
            <p>
              Añade enlaces opcionales a los informes (webs, videos, lecturas,
              etc).
            </p>
            <app-test-report-links
              [theme]="'avatar'"
              [links]="avatar.links"
              (changeEvent)="this.setLinks($event)"
            ></app-test-report-links>
          </div>
          <div class="">
            <button
              (click)="generateReport()"
              target="_blank"
              href="https://plataforma.intecc.org/courses/INICIACION-valoracion-anatomica-y-funcional"
              class="mt-4 flex w-full flex-row items-center justify-center overflow-hidden rounded-xl bg-inteccDark px-6 py-3 font-bold text-white transition-all hover:bg-inteccDark/80"
            >
              <i class="fas fa-file-pdf mr-2"></i>
              <span *ngIf="!generatingReport">Generar Informe</span>
              <span *ngIf="generatingReport">Generando...</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
