import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of, Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { tap } from 'rxjs/operators';
import { ModalService } from 'src/app/services/modal.service';
import { AppName, AppConfig } from 'src/config/app.config';
import { OverrideBackButtonService } from 'src/app/services/override-back-button.service';
import { UserConfig } from 'src/app/models/user-config.model';
import { UserConfigServiceService } from 'src/app/services/user-config-service.service';
import { CustomBrandingService } from 'src/app/services/custom-branding.service';
import { sleep } from 'src/app/utils/utils';
import { User } from 'src/app/models/user.model';
import { UserClientService } from 'src/app/services/user-client.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  menuOpen = false;
  show = false;
  subs = new Array<Subscription>();
  isClient = false;
  sideMenuOpen = false;
  inclinometerOpen = false;

  appName: AppName = AppConfig.app;

  userConfig: UserConfig;

  currentBrand;

  professional: User;

  professionalConfig: UserConfig;

  professionalConnectLinks$: Observable<
    { key: string; icon: string; url: string }[]
  >;

  constructor(
    public auth: AuthService,
    private modalService: ModalService,
    private overrideBackButton: OverrideBackButtonService,
    private configService: UserConfigServiceService,
    private customBrandingService: CustomBrandingService,
    private router: Router,
    private userClientService: UserClientService,
  ) {}

  ngOnInit() {
    this.subs.push(
      this.auth.user$
        .pipe(
          tap((user) => {
            this.show = !!user;
            this.isClient = user?.role === 'client';
          }),
        )
        .subscribe(),
    );

    this.professionalConnectLinks$ =
      this.userClientService.professionalConnectLinks$;

    this.subs.push(
      this.userClientService.professional$.subscribe((professional) => {
        this.professional = professional;
      }),

      this.userClientService.professionalConfig$.subscribe((config) => {
        this.professionalConfig = config;
      }),
    );

    this.currentBrand = this.customBrandingService.brand;
    this.configService.userConfig$.subscribe((config) => {
      this.userConfig = config;
    });
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  openAddClientModal() {
    this.modalService.openModal('create-edit-client', {});
  }

  openExportTestsModal() {
    this.modalService.openModal('export-tests', {});
  }

  async navigateToMyProfile() {
    this.closeSideMenu();
    await sleep(100);
    this.router.navigate(['my-profile']);
  }

  async navigateToMyClients() {
    this.closeSideMenu();
    await sleep(100);
    this.router.navigate(['clients']);
  }

  async navigateToFromSideMenu(route: string) {
    this.closeSideMenu();
    await sleep(100);
    this.router.navigate([route]);
  }

  async openAccountModal() {
    this.closeSideMenu();
    await sleep(100);
    this.modalService.openModal('account', {});
  }

  // editReportsConfig() {
  //   this.modalService.openModal('edit-reports-config');
  // }

  openSideMenu() {
    this.sideMenuOpen = true;
    this.overrideBackButton.override(() => {
      this.sideMenuOpen = false;
    });
  }

  closeSideMenu() {
    history.back();
  }

  async openMultimedia() {
    this.closeSideMenu();
    await sleep(100);
    const result = await this.modalService.openLateralModal('client-media', {
      onlyOriginals: false,
      selectionMode: false,
      selectionName: 'My VAF',
    });
  }

  openInclinometer() {
    this.inclinometerOpen = true;
  }

  onInclinometerClose() {
    this.inclinometerOpen = false;
  }

  reload() {
    location.reload();
  }

  async logout() {
    this.closeSideMenu();
    this.auth.signOut();
  }
}
