import { Component, OnInit, OnDestroy } from '@angular/core';
import { combineLatest, map, Observable, Subject, takeUntil, tap } from 'rxjs';
import { SubscriptionType } from 'src/shared/subscriptions.shared';
import { Client } from 'src/app/models/client.model';
import { AuthService } from 'src/app/services/auth.service';
import { UserClientService } from 'src/app/services/user-client.service';
import { LoadingState } from 'src/app/utils/utils';

@Component({
  selector: 'app-client-client',
  templateUrl: './client-client.component.html',
})
export class ClientClientComponent implements OnInit, OnDestroy {
  pageLoaded: LoadingState = 'loading';

  userClient$: Observable<Client>;
  userClient: Client;
  professionalSubscriptionType$: Observable<SubscriptionType>;

  private destroy$ = new Subject<void>();

  constructor(
    public auth: AuthService,
    private userClientService: UserClientService,
  ) {
    this.userClient$ = this.userClientService.userClient$.pipe(
      tap((client) => {
        this.userClient = client;
      }),
    );

    this.professionalSubscriptionType$ =
      this.userClientService.professionalSubscriptionType$;
  }

  ngOnInit() {
    combineLatest([this.auth.user$, this.userClient$])
      .pipe(
        takeUntil(this.destroy$),
        map(([user, userClient]) => ({
          user,
          userClient,
        })),
      )
      .subscribe({
        next: (data) => {
          // All observables have emitted values
          this.pageLoaded = 'idle';
        },
        error: (err) => {
          console.error('Error loading data:', err);
          this.pageLoaded = 'error';
        },
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
