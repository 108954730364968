<div class="content h-full">
  <ng-container [ngSwitch]="pageLoaded">
    <ng-container *ngSwitchCase="'loading'">
      <app-loader-feedback size="large"></app-loader-feedback>
    </ng-container>

    <ng-container *ngSwitchCase="'error'">
      <div class="flex h-full items-center justify-center">
        <app-error-feedback></app-error-feedback>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'idle'">
      <ng-container *ngIf="userClient; else noClient">
        <ng-container
          *ngIf="
            (professionalSubscriptionType$ | async) === 'InteccElite';
            else noEliteSubscription
          "
        >
          <!-- Inicio sección de Nivel Físico-->
          <div id="training-level-section">
            <div class="flex items-center text-2xl">
              <i class="fa-solid fa-chart-simple mr-4 text-xl text-intecc2"></i>
              <p class="">Nivel Físico</p>
            </div>
            <p class="mb-2 text-lg text-neutral-400">
              Su nivel físico actual, sus sesiones de entrenamiento y protocolos
            </p>
            <div class="mb-4 space-y-4 md:flex md:space-x-4 md:space-y-0">
              <button
                (click)="openTrainingAvatarModal()"
                class="text mx-auto flex w-full items-center justify-center rounded-lg bg-inteccDark px-4 py-3 font-semibold text-white shadow-sm"
              >
                <i class="fas fa-user"></i>
                <div class="ml-4 font-bold">Avatar de entrenamiento</div>
              </button>
            </div>
            <div class="h-[612px]">
              <app-training-avatar
                [isModal]="false"
                [openTrainingAvatarSideModal]="
                  openTrainingAvatarModal.bind(this)
                "
                [isUserClientView]="true"
              ></app-training-avatar>
            </div>
            <div
              class="text mx-auto mt-4 flex w-full max-w-md items-center justify-center rounded-lg bg-blue-100 px-4 py-3 font-semibold text-black shadow-sm"
            >
              <p class="text-lg font-bold">
                {{ clientLevelCategory }}
              </p>
              <span *ngIf="clientLevel">
                {{ ': ' + clientLevel }}
              </span>
            </div>
            <div class="mt-8">
              <!-- TODO: on click function for list items -->
              <!-- <app-items-list
                primaryColorClass="primary-2"
                secondaryColorClass="primary-9"
                [itemsObservable$]="trainingAvatarList$"
                [canUseFeature]="canViewTests"
                featureNotAvailableText="Empieza tu prueba gratuita para acceder a las valoraciones"
                [handleGenerateReport]="generateReportTrainingAvatars"
                [handleDeleteItems]="deleteTrainingAvatars"
              ></app-items-list> -->
            </div>
          </div>
          <!-- Inicio sección de Sesiones de entrenamiento-->
          <div id="training-sessions-section">
            <div class="flex items-center text-2xl">
              <i class="fa-solid fa-dumbbell mr-3 text-xl text-intecc2"></i>
              <p class="">Entrenamiento</p>
            </div>
            <p class="mb-2 text-lg text-neutral-400">
              Sesiones de entrenamiento
            </p>
            <app-items-list
              primaryColorClass="primary-2"
              secondaryColorClass="primary-9"
              [itemsObservable$]="clientTrainingSessionsList$"
              [canUseFeature]="true"
              [handleGenerateReport]="generateReportClientTrainingSessions"
              [isUserClientView]="true"
            ></app-items-list>
          </div>
          <!-- Inicio sección de "Protocolos my-vaf" -->
          <div>
            <div class="flex items-center text-2xl">
              <i class="fa-solid fa-list-check mr-3 text-xl text-intecc2"></i>
              <p class="">Protocolos</p>
            </div>
            <p class="mb-2 text-lg text-neutral-400">Test Personalizados</p>
            <app-items-list
              primaryColorClass="primary-2"
              secondaryColorClass="primary-9"
              [itemsObservable$]="myVafTestsList$"
              [canUseFeature]="true"
              featureNotAvailableText="Crea tus tests personalizados con VAF Premium"
              [handleGenerateReport]="generateReportMyVaf"
              [isUserClientView]="true"
            ></app-items-list>
          </div>
        </ng-container>

        <ng-template #noEliteSubscription>
          <app-no-elite-subscription-feedback></app-no-elite-subscription-feedback>
        </ng-template>
      </ng-container>
      <ng-template #noClient>
        <div
          class="flex h-full flex-col items-center justify-center text-center"
        >
          <i class="fa-solid fa-user-slash mb-4 text-6xl text-gray-400"></i>
          <div class="text-lg font-semibold text-gray-600">
            Debes unirte a un profesional para ver tus registros de cliente
          </div>
          <!-- <button
            (click)="joinRandomProfessional()"
            class="hover:bg-intecc1 mt-4 rounded-lg bg-intecc2 px-4 py-2 text-white"
          >
            Unirme a un profesional
          </button> -->
        </div>
      </ng-template>
    </ng-container>
  </ng-container>
</div>
