import {
  Component,
  Input,
  OnInit,
  WritableSignal,
  effect,
  signal,
  OnDestroy,
} from '@angular/core';
import {
  Subject,
  tap,
  debounceTime,
  takeUntil,
  distinctUntilChanged,
  firstValueFrom,
  of,
  from,
} from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Client } from 'src/app/models/client.model';
import {
  getDropdownLabelByLevel,
  levelDropdownOptions,
  SegmentKey,
  segmentsMap,
  TrainingAvatar,
  ClienLevelCategory,
  iconByLevel,
} from '../../../../../Server/functions/src/shared/training-avatar.shared';
import { AppConfig } from 'src/config/app.config';
import { OverrideBackButtonService } from 'src/app/services/override-back-button.service';
import { TestService } from 'src/app/services/test.service';
import { ReportsService } from 'src/app/services/reports.service';
import { ActionInProgressService } from 'src/app/services/action-in-progress.service';
import { ModalService } from 'src/app/services/modal.service';
import { ClientsService } from 'src/app/services/clients.service';
import {
  levelDefaultFeedback,
  UserConfig,
} from 'src/app/models/user-config.model';
import { FormGroup, FormBuilder } from '@angular/forms';
import { KeyValue } from '@angular/common';
import { UserConfigServiceService } from 'src/app/services/user-config-service.service';
import { TrainingAvatarConfigService } from 'src/app/services/training-avatar-config.service';
import { UserClientService } from 'src/app/services/user-client.service';
import { isEqualObject } from 'src/app/utils/utils';

type SelectedTabOption = 'resultados' | 'nivel';

@Component({
  selector: 'app-training-avatar',
  templateUrl: './training-avatar.component.html',
  styleUrl: './training-avatar.component.scss',
})
export class TrainingAvatarComponent implements OnInit, OnDestroy {
  constructor(
    private clientsService: ClientsService,
    private tests: TestService,
    private overrideBackButton: OverrideBackButtonService,
    private reportsService: ReportsService,
    private actionInProgressService: ActionInProgressService,
    private modalService: ModalService,
    private formBuilder: FormBuilder,
    private userConfigService: UserConfigServiceService,
    public trainingAvatarConfigService: TrainingAvatarConfigService,
    private userClientService: UserClientService,
  ) {
    this.initForm();
    effect(() => {
      if (this.isUserClientView || !this.currentClientTrainingAvatar) {
        return;
      }

      const previousState = structuredClone(this.currentClientTrainingAvatar);

      if (!this.currentClientTrainingAvatar.level) {
        this.currentClientTrainingAvatar.level = {};
      }
      this.currentClientTrainingAvatar.level.mmssEmpuje =
        this.mmssEmpujeLevel();
      this.currentClientTrainingAvatar.level.mmssTraccion =
        this.mmssTraccionLevel();
      this.currentClientTrainingAvatar.level.mmiiEmpuje =
        this.mmiiEmpujeLevel();
      this.currentClientTrainingAvatar.level.mmiiTraccion =
        this.mmiiTraccionLevel();
      this.currentClientTrainingAvatar.level.core = this.coreLevel();
      this.currentClientTrainingAvatar.level.metabolico =
        this.metabolicoLevel();

      if (this.isEmptyAvatarMock) {
        return;
      }

      if (!isEqualObject(previousState, this.currentClientTrainingAvatar)) {
        this.tests.updateTrainingAvatar(this.currentClientTrainingAvatar);
      }
    });

    this.nivelCommentsSubject
      .pipe(
        debounceTime(500),
        tap((value) => {
          if (this.isEmptyAvatarMock) {
            return;
          }
          this.currentClientTrainingAvatar.nivelComments = value;
          this.tests.updateTrainingAvatar(this.currentClientTrainingAvatar);
        }),
      )
      .subscribe();

    this.resultsCommentsSubject
      .pipe(
        debounceTime(500),
        tap((value) => {
          if (this.isEmptyAvatarMock) {
            return;
          }
          this.currentClientTrainingAvatar.resultsComments = value;
          this.tests.updateTrainingAvatar(this.currentClientTrainingAvatar);
        }),
      )
      .subscribe();

    this.segmentCommentsSubject
      .pipe(
        debounceTime(500),
        tap((value) => {
          if (this.isEmptyAvatarMock) {
            return;
          }
          this.currentClientTrainingAvatar.segmentComments[value.segment] =
            value.value;
          this.tests.updateTrainingAvatar(this.currentClientTrainingAvatar);
        }),
      )
      .subscribe();
  }

  private destroy$ = new Subject<void>();

  onClose = new Subject<any>();

  // @Input()
  client: AvatarModalData;

  @Input()
  data: {
    isUserClientView: boolean;
  };

  @Input()
  isModal = true;

  @Input() openTrainingAvatarSideModal!: () => void;

  private _isUserClientView: boolean | undefined;

  @Input()
  get isUserClientView(): boolean {
    return this._isUserClientView ?? this.data?.isUserClientView ?? false;
  }
  set isUserClientView(value: boolean) {
    this._isUserClientView = value;
  }

  clientSharedFolderUrl: string;

  loadingValues = false;

  selectedTab: SelectedTabOption = 'nivel';

  editMode = false;

  generatingReport = false;

  currentApp = AppConfig.app;

  userConfig: UserConfig;

  segmentsMap = segmentsMap;

  levelsNumbersList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  editLevelsConfigForm: FormGroup;

  currentClient: Client;
  currentClientTrainingAvatar: TrainingAvatar;
  isEmptyAvatarMock = false;
  clientLevelCategory: ClienLevelCategory;
  clientLevel: string;

  levelDropdownOptions = levelDropdownOptions;

  colorByLevel: Record<ClienLevelCategory, string> = {
    'Nivel sin determinar': 'bg-gray-200 text-gray-500',
    Amateur: '!bg-amber-100 !text-amber-800',
    Deportista: '!bg-emerald-200 !text-emerald-800',
    Atleta: '!bg-inteccLight !text-neutral-500',
    Élite: '!bg-inteccLight !text-neutral-500',
  };
  iconByLevel = iconByLevel;

  levelDefaultFeedback = levelDefaultFeedback;

  // client level dropdown
  mmssEmpujeLevel?: WritableSignal<number> = signal(undefined);
  mmssTraccionLevel?: WritableSignal<number> = signal(undefined);
  mmiiEmpujeLevel?: WritableSignal<number> = signal(undefined);
  mmiiTraccionLevel?: WritableSignal<number> = signal(undefined);
  coreLevel?: WritableSignal<number> = signal(undefined);
  metabolicoLevel?: WritableSignal<number> = signal(undefined);

  private nivelCommentsSubject = new Subject<string>();
  private resultsCommentsSubject = new Subject<string>();
  private segmentCommentsSubject = new Subject<{
    segment: SegmentKey;
    value: string;
  }>();

  ngOnInit(): void {
    let serviceToGetAvatarFrom: TestService | UserClientService;
    if (this.isUserClientView) {
      serviceToGetAvatarFrom = this.userClientService;
      this.userClientService.userClient$.subscribe((client) => {
        this.currentClient = client;
      });
    } else {
      serviceToGetAvatarFrom = this.tests;
      this.clientsService.currentClient$
        .pipe(
          tap((client) => {
            // to ensure the new value from the observer is the current client
            // this.loading$.next(false); // TODO: will I need this? IMPORTANT: pay attention to this, it might be needed
            this.currentClient = client;
          }),
        )
        .subscribe((client) => {
          this.client = client;
          this.clientSharedFolderUrl = this.client.sharedFolderUrl;
        });
    }

    serviceToGetAvatarFrom.currentClientTrainingAvatar$
      .pipe(
        switchMap((avatar) => {
          if (avatar) {
            this.isEmptyAvatarMock = false;
            return of(avatar);
          }
          this.isEmptyAvatarMock = true;
          return from(
            this.tests.createTrainingAvatarObject(this.currentClient.id),
          );
        }),
      )
      .subscribe((avatar) => {
        this.currentClientTrainingAvatar = avatar;
        if (!this.currentClientTrainingAvatar) {
          return;
        }

        this.metabolicoLevel.set(
          this.currentClientTrainingAvatar.level?.metabolico ?? undefined,
        );
        this.coreLevel.set(
          this.currentClientTrainingAvatar.level?.core ?? undefined,
        );
        this.mmiiEmpujeLevel.set(
          this.currentClientTrainingAvatar.level?.mmiiEmpuje ?? undefined,
        );
        this.mmiiTraccionLevel.set(
          this.currentClientTrainingAvatar.level?.mmiiTraccion ?? undefined,
        );
        this.mmssEmpujeLevel.set(
          this.currentClientTrainingAvatar.level?.mmssEmpuje ?? undefined,
        );
        this.mmssTraccionLevel.set(
          this.currentClientTrainingAvatar.level?.mmssTraccion ?? undefined,
        );
        this.currentClientTrainingAvatar.nivelComments =
          this.currentClientTrainingAvatar.nivelComments ?? '';
        this.currentClientTrainingAvatar.resultsComments =
          this.currentClientTrainingAvatar.resultsComments ?? '';
        this.currentClientTrainingAvatar.segmentComments =
          this.currentClientTrainingAvatar.segmentComments ?? {};
      });

    serviceToGetAvatarFrom.currentClientLevelCategory$.subscribe((level) => {
      this.clientLevelCategory = level;
    });

    serviceToGetAvatarFrom.currentClientLevel$.subscribe((level) => {
      this.clientLevel = level;
    });

    // Subscribe to form value changes
    this.editLevelsConfigForm.valueChanges
      .pipe(
        debounceTime(500), // Wait for 500ms of inactivity
        distinctUntilChanged(
          (prev, curr) => JSON.stringify(prev) === JSON.stringify(curr),
        ),
        takeUntil(this.destroy$),
      )
      .subscribe((formValue) => {
        this.saveUserConfig(formValue);
      });

    // Fetch UserConfig and populate form
    if (this.isUserClientView) {
      firstValueFrom(this.userClientService.professionalConfig$).then(
        (config) => {
          this.userConfig = config;
          this.populateFormWithUserConfig();
        },
      );

      // subscription to keep text updated but not form as it will interfere with the user typing
      this.userClientService.professionalConfig$.subscribe((config) => {
        this.userConfig = config;
      });
    } else {
      this.userConfigService.getConfig().then((config) => {
        this.userConfig = config;
        this.populateFormWithUserConfig();
      });

      // subscription to keep text updated but not form as it will interfere with the user typing
      this.userConfigService.userConfig$.subscribe((config) => {
        this.userConfig = config;
      });
    }
    // Subscribe to the shared showCustomLevelFeedback state
    this.trainingAvatarConfigService.showCustomLevelFeedback$
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.editLevelsConfigForm
          .get('showCustomLevelFeedback')
          .setValue(value);
      });

    // Subscribe to changes in the showCustomLevelFeedback control
    this.editLevelsConfigForm
      .get('showCustomLevelFeedback')
      .valueChanges.pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe((value) => {
        this.trainingAvatarConfigService.setShowCustomLevelFeedback(value);
      });
  }

  createAvatar() {
    this.tests.createTrainingAvatar(this.currentClient.id);
  }

  reloadPage() {
    window.location.reload();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  historyBackClose() {
    this.onClose.next('');
  }

  close() {
    history.back();
  }

  enterEditMode() {
    this.editMode = true;
    this.overrideBackButton.override(() => (this.editMode = false));
  }

  exitEditMode() {
    history.back();
  }

  changeSelectedTab(selectedTabCode: SelectedTabOption) {
    this.selectedTab = selectedTabCode;
  }

  onOptionSelected(option: string) {
    console.log('Selected option:', option);
    // Handle the selected option
  }

  initForm() {
    const formControls = {};
    for (const segment of Object.keys(this.segmentsMap)) {
      for (let level = 1; level <= 10; level++) {
        formControls[`${segment}_${level}`] = [''];
      }
    }
    this.editLevelsConfigForm = this.formBuilder.group({
      showCustomLevelFeedback: [false],
      levels: this.formBuilder.group(formControls),
    });
  }

  populateFormWithUserConfig() {
    if (this.userConfig && this.userConfig.segmentsLevelsFeedback) {
      this.editLevelsConfigForm
        .get('showCustomLevelFeedback')
        .setValue(
          this.userConfig.segmentsLevelsFeedback.showCustomLevelFeedback ||
            false,
        );

      for (const [segment, levels] of Object.entries(
        this.userConfig.segmentsLevelsFeedback,
      )) {
        if (segment === 'showCustomLevelFeedback') continue;
        for (const [level, feedbackText] of Object.entries(levels)) {
          const controlName = `${segment}_${level}`;
          if (this.editLevelsConfigForm.get(`levels.${controlName}`)) {
            this.editLevelsConfigForm
              .get(`levels.${controlName}`)
              .setValue(feedbackText);
          }
        }
      }
    }
  }

  saveUserConfig(formValue: any) {
    const segmentsLevelsFeedback: Partial<
      UserConfig['segmentsLevelsFeedback']
    > = {
      showCustomLevelFeedback: formValue.showCustomLevelFeedback,
    };
    for (const [key, value] of Object.entries(formValue.levels)) {
      if (!value) continue;

      const [segment, level] = key.split('_');
      if (!segmentsLevelsFeedback[segment]) {
        segmentsLevelsFeedback[segment] = {};
      }
      segmentsLevelsFeedback[segment][parseInt(level)] = value as string;
    }

    this.userConfigService
      .updateUserConfig({
        segmentsLevelsFeedback: segmentsLevelsFeedback,
      })
      .then(() => {
        console.log('User config saved successfully');
      })
      .catch((error) => {
        console.error('Error saving user config:', error);
      });

    // Update the shared state
    this.trainingAvatarConfigService.setShowCustomLevelFeedback(
      formValue.showCustomLevelFeedback,
    );
  }

  originalOrder = (
    a: KeyValue<string, string>,
    b: KeyValue<string, string>,
  ): number => {
    return 0;
  };

  getLevelDropdownLabel(
    segment: SegmentKey,
    level: number | undefined,
  ): string {
    if (level === undefined) {
      return this.levelDefaultFeedback[segment][0].label; // Returns '-' for undefined level
    }
    return (
      this.levelDefaultFeedback[segment].find(
        (option) => option.value === level,
      )?.label || '-'
    );
  }

  getLevelTextByNumber(level: number) {
    return this.tests.getLevelTextByNumber(level);
  }

  getDropdownLabelByLevel = getDropdownLabelByLevel;

  onNivelCommentsChange(value: string) {
    if (this.isUserClientView) {
      return;
    }
    this.nivelCommentsSubject.next(value);
  }

  onResultsCommentsChange(value: string) {
    if (this.isUserClientView) {
      return;
    }
    this.resultsCommentsSubject.next(value);
  }

  onSegmentCommentsChange(value: string, segment: SegmentKey) {
    if (this.isUserClientView) {
      return;
    }
    this.segmentCommentsSubject.next({ segment, value });
  }

  async editClient(data: any) {
    await this.modalService.openModal('create-edit-client', data);
  }

  setMultimediaLink(value: any) {
    value = value.trim();
    if (this.isEmptyAvatarMock) {
      return;
    }
    this.currentClientTrainingAvatar.multimediaLink = value;
    this.tests.updateTrainingAvatar(this.currentClientTrainingAvatar);
  }

  setLinks(value: any) {
    if (this.isEmptyAvatarMock) {
      return;
    }
    this.currentClientTrainingAvatar.links = value;
    this.tests.updateTrainingAvatar(this.currentClientTrainingAvatar);
  }

  getLinks() {
    return this.currentClientTrainingAvatar.links;
  }

  async generateReport() {
    if (this.generatingReport) {
      return;
    }
    this.generatingReport = true;
    this.actionInProgressService.component.show('Generando informe...');
    await this.reportsService.generateReport([
      this.currentClientTrainingAvatar,
    ]);
    this.actionInProgressService.component.hide();
    this.generatingReport = false;
  }
}

export type AvatarModalData = Client;
