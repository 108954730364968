export const USER_CONFIG_PATH = "user-config";

export interface UserConfig {
  //  proffesional info
  education?: string;
  displayEducation?: string;
  experience?: string;
  displayExperience?: string;
  others?: string;
  displayOthers?: string;
  profilePhotoUrl?: string;
  displayProfilePhoto?: boolean;

  // services - info buttons
  questionnaireExplanationLink?: string;
  displayQuestionnaireExplanationLink?: boolean;
  calendarExplanationLink?: string;
  displayCalendarExplanationLink?: boolean;
  metricsExplanationLink?: string;
  displayMetricsExplanationLink?: boolean;
  appointmentExplanationLink?: string;
  displayAppointmentExplanationLink?: boolean;
  hireServiceExplanationLink?: string;
  displayHireServiceExplanationLink?: boolean;

  // services - text and links
  customAppointmentLabel?: string;
  displayCustomAppointmentLabel?: boolean;
  appointmentLink?: string;
  customHireServiceLabel?: string;
  displayCustomHireServiceLabel?: boolean;
  hireServiceLink?: string;

  // connect links
  connectLinks?: {
    web?: string;
    displayWeb?: boolean;
    location?: string;
    displayLocation?: boolean;
    connectEmail?: string;
    displayConnectEmail?: boolean;
    connectPhone?: string;
    displayConnectPhone?: boolean;
    whatsapp?: string;
    displayWhatsapp?: boolean;
    appointmentsCalendar?: string;
    displayAppointmentsCalendar?: boolean;
    paymentsLink?: string;
    displayPaymentsLink?: boolean;
    news?: string;
    displayNews?: boolean;
    instagram?: string;
    displayInstagram?: boolean;
    facebook?: string;
    displayFacebook?: boolean;
    twitter?: string;
    displayTwitter?: boolean;
    tiktok?: string;
    displayTiktok?: boolean;
    other?: string;
    displayOther?: boolean;
  };

  // billing info
  companyName?: string;
  displayCompanyName?: boolean;
  dniCif?: string;
  displayDniCif?: boolean;
  name?: string;
  displayName?: boolean;
  address?: string;
  displayAddress?: boolean;
  accountNumber?: string;
  displayAccountNumber?: boolean;
  phone?: string;
  displayPhone?: boolean;
  email?: string;
  displayEmail?: boolean;

  // reports config
  reportsConfig?: {
    line1?: string;
    displayLine1?: boolean;
    line2?: string;
    displayLine2?: boolean;
    line3?: string;
    displayLine3?: boolean;
    logoUrl?: string;
    showOnReport?: boolean;
  };

  // proffesional links
  customAnamnesisLink?: string;
  customClientsLink?: string;
  customLinks?: {
    name?: string;
    url?: string;
  }[];
  counters?: {
    reportsGenerated?: number;
  };

  // Add new field for segments levels feedback
  segmentsLevelsFeedback?: {
    [K in
      | "mmssEmpuje"
      | "mmssTraccion"
      | "mmiiEmpuje"
      | "mmiiTraccion"
      | "core"
      | "metabolico"]?: {
      [L in 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10]?: string;
    };
  } & {
    showCustomLevelFeedback?: boolean;
  };
}

export const levelDefaultFeedback = {
  mmssEmpuje: [
    { value: undefined, label: "-" },
    { value: 1, label: "Flexiones 5/10R" },
    {
      value: 2,
      label: "Flexiones 10/20R",
    },
    {
      value: 3,
      label: "Flexiones 20/30R",
    },
    {
      value: 4,
      label: "Flexiones +20/+30R",
    },
    {
      value: 5,
      label: "Press Banca 10R, PC 0,2/0,4 (1m/s)",
    },
    {
      value: 6,
      label: "Press Banca 10R, PC 0,3/0,5 (0,8m/s)",
    },
    {
      value: 7,
      label: "Press Banca 10R, PC 0,5/0,75 (0,6m/s)",
    },
    {
      value: 8,
      label: "Press Banca 5R, PC 0,75/1 (0,5m/s)",
    },
    {
      value: 9,
      label: "Press Banca 5R, PC 1/1,25 (0,3m/s)",
    },
    {
      value: 10,
      label: "Press Banca 3R, PC 1,25/1,5 (0,2m/s)",
    },
  ],
  mmssTraccion: [
    { value: undefined, label: "-" },
    {
      value: 1,
      label: "Dominada Iso 10s Sup / 20s Prona",
    },
    {
      value: 2,
      label: "Dominada Iso 20s Sup / 30s Prona",
    },
    {
      value: 3,
      label: "Dominada Iso 30s Sup / 40s Prona",
    },
    {
      value: 4,
      label: "Dominada Iso 40s Sup / 50s Prona",
    },
    {
      value: 5,
      label: "Dominada Iso 50s Sup / 60s Prona (1m/s)",
    },
    {
      value: 6,
      label: "Dominada Iso 60s Sup / Prona 5R (0,9m/s)",
    },
    {
      value: 7,
      label: "Dominada Sup 4R / Prona 10R (0,75m/s)",
    },
    {
      value: 8,
      label: "Dominada Sup 7R / Prona 15R (0,6m/s)",
    },
    {
      value: 9,
      label: "Dominada Sup 10R / Prona 20R (0,4m/s)",
    },
    {
      value: 10,
      label: "Dominada Sup +10R / Prona +20R (0,2m/s)",
    },
  ],
  mmiiEmpuje: [
    { value: undefined, label: "-" },
    {
      value: 1,
      label: "Sit & Raise 30s",
    },
    {
      value: 2,
      label: "Sit & Raise 60s",
    },
    { value: 3, label: "Sentadilla KB 10R, 6/12Kg" },
    { value: 4, label: "Sentadilla KB 10R, 12/24Kg" },
    { value: 5, label: "Sentadilla KB 10R, 24/32Kg Barra 0,3/0,5 (1m/s)" },
    {
      value: 6,
      label: "Sentadilla Barra 10R, PC 0,5/0,75 (0,85m/s)",
    },
    {
      value: 7,
      label: "Sentadilla Barra 10R, PC 0,75/1 (0,7m/s)",
    },
    {
      value: 8,
      label: "Sentadilla Barra 5R, PC 1/1,25 (0,6m/s)",
    },
    {
      value: 9,
      label: "Sentadilla Barra 5R, PC 1,25/1,5 (0,45m/s)",
    },
    {
      value: 10,
      label: "Sentadilla Barra 3R, PC 1,5/2 (0,3m/s)",
    },
  ],
  mmiiTraccion: [
    { value: undefined, label: "-" },
    { value: 1, label: "Isométrico 30s" },
    {
      value: 2,
      label: "Peso Muerto 10R KB 6/12Kg",
    },
    {
      value: 3,
      label: "Peso Muerto 10R KB 12/24Kg",
    },
    {
      value: 4,
      label: "Peso Muerto 10R KB 24/32Kg",
    },
    {
      value: 5,
      label: "Peso Muerto Barra 10R, PC 0,5 (1m/s)",
    },
    {
      value: 6,
      label: "Peso Muerto Barra 10R, PC 0,75 (0,8m/s)",
    },
    {
      value: 7,
      label: "Peso Muerto Barra 10R, PC 1 (0,65m/s)",
    },
    {
      value: 8,
      label: "Peso Muerto Barra 5R, PC 1,5 (0,5m/s)",
    },
    {
      value: 9,
      label: "Peso Muerto Barra 5R, PC 2 (0,4m/s)",
    },
    {
      value: 10,
      label: "Peso Muerto Barra 3R, PC 2,5 (0,2m/s)",
    },
  ],
  core: [
    { value: undefined, label: "-" },
    {
      value: 1,
      label: "ISS (4) Coactivación 15s",
    },
    {
      value: 2,
      label: "ISS (4) Coactivación 30s",
    },
    { value: 3, label: "ISS (4) Estable (Uniplanar) 3x15s" },
    { value: 4, label: "ISS (4) Estable (Uniplanar) 3x30s" },
    {
      value: 5,
      label: "ISS (4) Inestable (Uniplanar) 3x10R 30s",
    },
    {
      value: 6,
      label: "ISS (4) Inestable (Uniplanar) 3x10R 30s (Bosu)",
    },
    {
      value: 7,
      label: "ISS (4) Inestable (Multiplanar) 3x10R 30s (Elástico)",
    },
    {
      value: 8,
      label: "ISS (4) Inestable (Multiplanar) 3x10R 30s (KB)",
    },
    { value: 9, label: "Rueda completa (Rodillas) 3x10R" },
    { value: 10, label: "Rueda completa (Pies) 3x10R" },
  ],
  metabolico: [
    { value: undefined, label: "-" },
    { value: 1, label: "Carrera / Bici +1min" },
    { value: 2, label: "Carrera / Bici +5min" },
    { value: 3, label: "Carrera / Bici +10min" },
    { value: 4, label: "Carrera / Bici +20min" },
    { value: 5, label: "Carrera / Bici +30min" },
    {
      value: 6,
      label: "Carrera / Bici 30min VAM +10 (5Km) o W/Kg 2/2,5",
    },
    {
      value: 7,
      label: "Carrera / Bici 30min VAM +12 (6Km) o W/Kg 2,5/3",
    },
    {
      value: 8,
      label: "Carrera / Bici 30min VAM +14 (7Km) o W/Kg 3/3,5",
    },
    {
      value: 9,
      label: "Carrera / Bici 30min VAM +16 (8Km) o W/Kg 3,5/4",
    },
    {
      value: 10,
      label: "Carrera / Bici 30min VAM +18 (9Km) o W/Kg 4/4,5",
    },
  ],
} as const;
