<div class="content h-full">
  <ng-container [ngSwitch]="pageLoaded">
    <ng-container *ngSwitchCase="'loading'">
      <app-loader-feedback size="large"></app-loader-feedback>
    </ng-container>

    <ng-container *ngSwitchCase="'error'">
      <div class="flex h-full items-center justify-center">
        <app-error-feedback></app-error-feedback>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'idle'">
      <div class="flex h-full flex-col items-center justify-center">
        <div class="relative mb-4 h-44 w-44">
          <img
            class="h-full w-full object-contain"
            [src]="
              professionalConfig?.reportsConfig?.logoUrl ||
              '../../../assets/images/intecc-with-bg-rounded.png'
            "
            alt=""
          />
        </div>

        <ng-container
          *ngIf="professional$ | async as professional; else noProfessional"
        >
          <ng-container
            *ngIf="
              (professionalSubscriptionType$ | async) === 'InteccElite';
              else noEliteAccess
            "
          >
            <div
              *ngIf="shouldDisplayFirstBox"
              class="mb-8 w-full max-w-lg space-y-4 rounded-2xl bg-white px-4 py-6 shadow-md shadow-neutral-200"
            >
              <div class="space-y-2 text-center text-lg font-semibold">
                <div *ngIf="professionalConfig.reportsConfig.displayLine1">
                  {{ professionalConfig.reportsConfig.line1 }}
                </div>
                <div *ngIf="professionalConfig.reportsConfig.displayLine2">
                  {{ professionalConfig.reportsConfig.line2 }}
                </div>
                <div *ngIf="professionalConfig.reportsConfig.displayLine3">
                  {{ professionalConfig.reportsConfig.line3 }}
                </div>
              </div>
            </div>

            <div
              class="w-full max-w-lg space-y-2 rounded-2xl bg-white px-4 py-6 shadow-md shadow-neutral-200"
            >
              <ng-container *ngIf="connectLinks$ | async as connectLinks">
                <div
                  *ngIf="connectLinks.length"
                  class="mb-8 flex flex-wrap justify-center space-x-3 space-y-1"
                >
                  @for (connectLink of connectLinks; track connectLink) {
                    <a
                      [href]="
                        connectLink.key === 'connectEmail' &&
                        !connectLink.url.startsWith('mailto:')
                          ? 'mailto:' + connectLink.url
                          : connectLink.url
                      "
                      target="_blank"
                      class="flex shrink-0 items-center justify-center p-2 text-3xl text-neutral-600 visited:text-neutral-600"
                    >
                      <i class="{{ connectLink.icon }}"></i>
                    </a>
                  }
                </div>
              </ng-container>

              <a
                routerLink="/c/client"
                class="flex w-full items-center justify-center rounded-lg bg-blue-100 px-4 py-3 font-semibold text-black"
              >
                <div class="grid grid-cols-[40px_100px] place-items-center">
                  <div class="mr-2"><i class="fas fa-user"></i></div>
                  <div class="place-self-start">Cliente</div>
                </div>
              </a>
              <a
                routerLink="/c/tests"
                class="flex w-full items-center justify-center rounded-lg bg-blue-100 px-4 py-3 font-semibold text-black"
              >
                <div class="grid grid-cols-[40px_100px] place-items-center">
                  <div class="mr-2"><i class="fas fa-heart"></i></div>
                  <div class="place-self-start">Valoración</div>
                </div>
              </a>
              <a
                routerLink="/c/training"
                class="flex w-full items-center justify-center rounded-lg bg-blue-100 px-4 py-3 font-semibold text-black"
              >
                <div class="grid grid-cols-[40px_100px] place-items-center">
                  <div class="mr-2"><i class="fas fa-dumbbell"></i></div>
                  <div class="place-self-start">Entrenamiento</div>
                </div>
              </a>
              <a
                routerLink="/c/professional"
                class="flex w-full items-center justify-center rounded-lg bg-blue-100 px-4 py-3 font-semibold text-black"
              >
                <div class="grid grid-cols-[40px_100px] place-items-center">
                  <div class="mr-2"><i class="fas fa-user-tie"></i></div>
                  <div class="place-self-start">Profesional</div>
                </div>
              </a>
            </div>
          </ng-container>
          <ng-template #noEliteAccess>
            <app-no-elite-subscription-feedback></app-no-elite-subscription-feedback>
          </ng-template>
        </ng-container>

        <ng-template #noProfessional>
          <div
            class="w-full max-w-lg space-y-2 rounded-2xl bg-white px-4 py-6 shadow-md shadow-neutral-200"
          >
            <div class="text-center text-lg font-semibold">
              No tienes un profesional asignado.
            </div>
          </div>
          <p class="mt-6 text-center text-sm font-semibold text-gray-400">
            Ponte en contacto con el profesional y dile tu email de registro.
            <br class="hidden sm:block" />
            No olvides rellenar previamente el
            <a class="text-intecc" [routerLink]="['/c/client/questionnaire']"
              >cuestionario</a
            >.
          </p>
        </ng-template>
      </div>
    </ng-container>
  </ng-container>
</div>
