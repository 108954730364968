<div class="content h-full">
  <ng-container [ngSwitch]="pageLoaded">
    <ng-container *ngSwitchCase="'loading'">
      <app-loader-feedback size="large"></app-loader-feedback>
    </ng-container>

    <ng-container *ngSwitchCase="'error'">
      <div class="flex h-full items-center justify-center">
        <app-error-feedback></app-error-feedback>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'idle'">
      <div class="flex h-full items-center justify-center">
        <!-- Three white boxes with buttons -->
        <div class="w-full max-w-lg space-y-4">
          <!-- Questionnaire Box -->
          <div class="w-full rounded-lg bg-white p-6 shadow-sm">
            <h3 class="mb-4 text-center text-lg font-semibold text-gray-800">
              Rellena el cuestionario inicial
            </h3>
            <a
              [routerLink]="['/c/client/questionnaire']"
              href="#"
              class="block w-full rounded-lg bg-intecc px-4 py-6 text-center font-semibold text-white"
              [class.cursor-not-allowed]="true"
              [class.opacity-50]="true"
              [class.pointer-events-none]="true"
            >
              Cuestionario
            </a>
          </div>

          <!-- Calendar Box -->
          <div class="w-full rounded-lg bg-white p-6 shadow-sm">
            <h3 class="mb-4 text-center text-lg font-semibold text-gray-800">
              Próximas sesiones y citas
            </h3>
            <a
              href="#"
              class="block w-full rounded-lg bg-intecc px-4 py-6 text-center font-semibold text-white"
              [class.cursor-not-allowed]="!userClient"
              [class.opacity-50]="!userClient"
              [class.pointer-events-none]="!userClient"
              [class.cursor-not-allowed]="true"
              [class.opacity-50]="true"
              [class.pointer-events-none]="true"
            >
              Calendario
            </a>
          </div>

          <!-- Metrics Box -->
          <div class="w-full rounded-lg bg-white p-6 shadow-sm">
            <h3 class="mb-4 text-center text-lg font-semibold text-gray-800">
              Registro diario del cliente
            </h3>
            <a
              href="#"
              class="block w-full rounded-lg bg-intecc px-4 py-6 text-center font-semibold text-white"
              [class.cursor-not-allowed]="!userClient"
              [class.opacity-50]="!userClient"
              [class.pointer-events-none]="!userClient"
              [class.cursor-not-allowed]="true"
              [class.opacity-50]="true"
              [class.pointer-events-none]="true"
            >
              Métricas y evolución
            </a>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
