import {
  Component,
  OnInit,
  ViewChild,
  WritableSignal,
  signal,
  OnDestroy,
  AfterViewInit,
} from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  combineLatest,
  lastValueFrom,
  Subject,
  from,
} from 'rxjs';
import { Client } from 'src/app/models/client.model';
import { ClientsService } from 'src/app/services/clients.service';
import { ActivatedRoute } from '@angular/router';
import {
  map,
  shareReplay,
  startWith,
  switchMap,
  take,
  tap,
  takeUntil,
} from 'rxjs/operators';
import { TestService } from 'src/app/services/test.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { Test, TestType } from '../../../shared/test.outside';
import { ConfirmActionDialogService } from 'src/app/services/confirm-action-dialog.service';
import { OverrideBackButtonService } from 'src/app/services/override-back-button.service';
import { SubscriptionsService } from 'src/app/services/subscriptions.service';
import { SubPlanCodes } from 'src/app/models/subscription.model';
import { ModalKey, ModalService } from 'src/app/services/modal.service';
import { ReportsService } from 'src/app/services/reports.service';
import { ActionInProgressService } from 'src/app/services/action-in-progress.service';
import { CanIService } from 'src/app/services/can-i.service';
import { environment } from 'src/environments/environment';
import { AppConfig } from 'src/config/app.config';
import { NewValorationMenuComponent } from 'src/app/components/new-valoration-menu/new-valoration-menu.component';
import { MyVafMenuComponent } from 'src/app/components/my-vaf-menu/my-vaf-menu.component';
import {
  getProtocolInitials,
  MyVafProtocolDef,
  MyVafProtocolTest,
} from 'src/shared/myvaf.outside';
import { MyvafService } from 'src/app/services/myvaf.service';
import { AuthService } from 'src/app/services/auth.service';
import { LoadingState, safeToPromise, sleep } from 'src/app/utils/utils';
import { ListItem } from 'src/app/items-list/items-list.component';
import { TrainingSessionsMenuComponent } from 'src/app/components/training-sessions-menu/training-sessions-menu.component';
import {
  ClientTrainingSession,
  TrainingSessionProtocolDef,
} from '../../../../../Server/functions/src/shared/training-session-definition.model';
import { TrainingSessionsService } from 'src/app/services/training-sessions.service';
import {
  ClientCorrectiveSession,
  CorrectiveSessionProtocolDef,
} from '../../../../../Server/functions/src/shared/corrective-session-definition.model';
import { CorrectiveSessionsService } from 'src/app/services/corrective-sessions.service';
import { CorrectiveSessionsMenuComponent } from 'src/app/components/corrective-sessions-menu/corrective-sessions-menu.component';
import {
  ClienLevelCategory,
  TrainingAvatar,
} from '../../../../../Server/functions/src/shared/training-avatar.shared';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { UserConfig } from 'src/app/models/user-config.model';
import { UserConfigServiceService } from 'src/app/services/user-config-service.service';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.scss'],
})
export class ClientComponent implements OnInit, OnDestroy, AfterViewInit {
  constructor(
    public clientsService: ClientsService,
    private userConfigService: UserConfigServiceService,
    private navigationService: NavigationService,
    private route: ActivatedRoute,
    public testService: TestService,
    public trainingSessionsService: TrainingSessionsService,
    public correctiveSessionsService: CorrectiveSessionsService,
    private nav: NavigationService,
    private confirmActionService: ConfirmActionDialogService,
    public overrideBackButton: OverrideBackButtonService,
    private subscriptionsService: SubscriptionsService,
    private modalService: ModalService,
    private reportsServices: ReportsService,
    private actionInProgressService: ActionInProgressService,
    private canI: CanIService,
    private myVafService: MyvafService,
    private auth: AuthService,
    private snackbarService: SnackbarService,
  ) {
    // Bindings in functions passed to other components
    this.generateReportForValorations =
      this.generateReportForValorations.bind(this);
    this.deleteValorations = this.deleteValorations.bind(this);
    this.handleShowToClientTests = this.handleShowToClientTests.bind(this);

    this.generateReportMyVaf = this.generateReportMyVaf.bind(this);
    this.deleteValorationsMyVaf = this.deleteValorationsMyVaf.bind(this);
    this.handleShowToClientMyVafTests =
      this.handleShowToClientMyVafTests.bind(this);

    this.generateReportClientTrainingSessions =
      this.generateReportClientTrainingSessions.bind(this);
    this.deleteClientTrainingSessions =
      this.deleteClientTrainingSessions.bind(this);
    this.handleShowToClientTrainingSessions =
      this.handleShowToClientTrainingSessions.bind(this);

    this.generateReportClientCorrectiveSessions =
      this.generateReportClientCorrectiveSessions.bind(this);
    this.deleteClientCorrectiveSessions =
      this.deleteClientCorrectiveSessions.bind(this);
    this.handleShowToClientCorrectiveSessions =
      this.handleShowToClientCorrectiveSessions.bind(this);

    this.generateReportTrainingAvatars =
      this.generateReportTrainingAvatars.bind(this);
    this.deleteTrainingAvatars = this.deleteTrainingAvatars.bind(this);
    this.handleShowToClientTrainingAvatars =
      this.handleShowToClientTrainingAvatars.bind(this);
  }

  loading$ = new BehaviorSubject<boolean>(true);

  userConfig: UserConfig;

  newValorationMenuOpen = false;

  client$: Observable<Client>;

  clientId$: Observable<string>;

  addingNewTest = false;

  currentClient: Client;

  clientTestsList$: Observable<ListItem<Test>[]>;

  myVafTestsList$: Observable<ListItem<MyVafProtocolTest>[]>;

  clientTrainingSessionsList$: Observable<ListItem<ClientTrainingSession>[]>;

  clientCorrectiveSessionsList$: Observable<
    ListItem<ClientCorrectiveSession>[]
  >;

  trainingAvatarList$: Observable<ListItem<TrainingAvatar>[]>;

  canViewTests = false;

  canUseMyVaf = false;

  canViewTrainingSessions = false;

  canViewCorrectiveSessions = false;

  subscribeButtonLoading = false;

  generatingReport = false;

  hideOtherApps = environment.hideOtherApps;

  currentApp = AppConfig.app;

  @ViewChild(NewValorationMenuComponent, { static: false })
  newValorationMenuComponent: NewValorationMenuComponent;

  @ViewChild(MyVafMenuComponent, { static: false })
  myVafMenuComponent: MyVafMenuComponent;

  @ViewChild(TrainingSessionsMenuComponent, { static: false })
  trainingSessionsMenuComponent: TrainingSessionsMenuComponent;

  @ViewChild(CorrectiveSessionsMenuComponent, { static: false })
  correctiveSessionMenuComponent: CorrectiveSessionsMenuComponent;

  myVafProtocolDefs: Array<MyVafProtocolDef> = [];
  myVafProtocolDefs$: Observable<MyVafProtocolDef[]>;
  trainingSessionProtocolDefs$: Observable<TrainingSessionProtocolDef[]>;
  trainingSessionProtocolDefs: Array<TrainingSessionProtocolDef> = [];
  correctiveSessionProtocolDefs$: Observable<CorrectiveSessionProtocolDef[]>;
  correctiveSessionProtocolDefs: Array<CorrectiveSessionProtocolDef> = [];

  newCorrectiveSessionFromUrlOpen: boolean;
  correctiveSessionSharedUrl: string;
  creatingCorrectiveSessionFromUrl = false;

  newTrainingSessionFromUrlOpen: boolean;
  trainingSessionSharedUrl: string;
  creatingTrainingSessionFromUrl = false;

  newProtocolFromUrlOpen: boolean;
  protocolSharedUrl: string;
  creatingProtocolFromUrl = false;

  levelDropdownOptions = [
    { value: undefined, label: '-' },
    { value: 1, label: '01 - Amateur' },
    { value: 2, label: '02 - Amateur' },
    { value: 3, label: '03 - Amateur' },
    { value: 4, label: '04 - Deportista' },
    { value: 5, label: '05 - Deportista' },
    { value: 6, label: '06 - Deportista' },
    { value: 7, label: '07 - Atleta' },
    { value: 8, label: '08 - Atleta' },
    { value: 9, label: '09 - Élite' },
    { value: 10, label: '10 - Élite' },
  ] as const;

  // client level dropdown
  mmssEmpujeLevel?: WritableSignal<number> = signal(undefined);
  mmssTraccionLevel?: WritableSignal<number> = signal(undefined);
  mmiiEmpujeLevel?: WritableSignal<number> = signal(undefined);
  mmiiTraccionLevel?: WritableSignal<number> = signal(undefined);
  coreLevel?: WritableSignal<number> = signal(undefined);
  metabolicoLevel?: WritableSignal<number> = signal(undefined);
  clientLevelCategory: ClienLevelCategory;
  clientLevel: string;

  pageLoaded: LoadingState = 'loading';
  private destroy$ = new Subject<void>();

  isActionsExpanded: boolean = false;

  ngOnInit() {
    this.clientId$ = this.route.paramMap.pipe(
      map((paramMap) => paramMap.get('id')),
    );
    this.client$ = this.clientId$.pipe(
      tap(() => {
        this.loading$.next(true);
      }),
      tap((clientId) => {
        return this.clientsService.setCurrentClient(clientId);
      }),
      switchMap((clientId) => {
        return this.clientsService.currentClient$.pipe(
          tap((client) => {
            // to ensure the new value from the observer is the current client
            if (clientId === client.id) {
              this.loading$.next(false);
              this.currentClient = client;
            }
          }),
        );
      }),
      shareReplay(1),
      startWith(null),
    );
    this.client$.subscribe(); // this subscription is needed to trigger the client$ observable
    this.testService.currentClientTrainingAvatar$.subscribe(
      (trainingAvatar) => {
        this.metabolicoLevel.set(
          trainingAvatar?.level?.metabolico ?? undefined,
        );
        this.coreLevel.set(trainingAvatar?.level?.core ?? undefined);
        this.mmiiEmpujeLevel.set(
          trainingAvatar?.level?.mmiiEmpuje ?? undefined,
        );
        this.mmiiTraccionLevel.set(
          trainingAvatar?.level?.mmiiTraccion ?? undefined,
        );
        this.mmssEmpujeLevel.set(
          trainingAvatar?.level?.mmssEmpuje ?? undefined,
        );
        this.mmssTraccionLevel.set(
          trainingAvatar?.level?.mmssTraccion ?? undefined,
        );
      },
    );

    // this.subscriptionsService.hasActiveSubscription([PlanIds.RaquisPro, PlanIds.RaquisStart, PlanIds.RaquisPremium]).then(
    //     result => this.canViewTests = result
    // );
    this.canI
      .canViewTests(AppConfig.app)
      .then((result) => (this.canViewTests = result));

    this.canI
      .canUseMyVaf(AppConfig.app)
      .then((result) => (this.canUseMyVaf = result));

    this.canI
      .canViewTrainingSessions(AppConfig.app)
      .then((result) => (this.canViewTrainingSessions = result));

    this.canI
      .canViewCorrectiveSessions(AppConfig.app)
      .then((result) => (this.canViewCorrectiveSessions = result));

    this.init();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngAfterViewInit() {
    if (this.navigationService.openTrainingSessionsMenu) {
      this.navigationService.openTrainingSessionsMenu = false;
      // Add a small delay to ensure component is initialized
      setTimeout(() => {
        this.openTrainingSessionsMenu();
      }, 30);
    }

    if (this.navigationService.openMyVafMenu) {
      this.navigationService.openMyVafMenu = false;
      setTimeout(() => {
        this.openMyVafMenu();
      }, 30);
    }

    if (this.navigationService.openCorrectiveSessionsMenu) {
      this.navigationService.openCorrectiveSessionsMenu = false;
      setTimeout(() => {
        this.openCorrectiveSessionsMenu();
      }, 30);
    }
  }

  async init() {
    console.log('init executes');
    this.userConfig = await safeToPromise(this.userConfigService.userConfig$);

    this.testService.currentClientLevelCategory$.subscribe((level) => {
      this.clientLevelCategory = level;
    });

    this.testService.currentClientLevel$.subscribe((level) => {
      this.clientLevel = level;
    });

    const user = await this.auth.getUser();
    this.myVafProtocolDefs$ = await this.myVafService.getProtocols$(user.uid);
    this.myVafProtocolDefs$.subscribe((defs) => {
      this.myVafProtocolDefs = defs;
    });
    this.trainingSessionProtocolDefs$ =
      await this.trainingSessionsService.getTrainingSessionProtocols$(user.uid);
    this.trainingSessionProtocolDefs$.subscribe((defs) => {
      this.trainingSessionProtocolDefs = defs;
    });

    this.correctiveSessionProtocolDefs$ =
      await this.correctiveSessionsService.getCorrectiveSessionProtocols$(
        user.uid,
      );
    this.correctiveSessionProtocolDefs$.subscribe((defs) => {
      this.correctiveSessionProtocolDefs = defs;
    });

    this.clientTestsList$ = this.testService.currentClientTests$.pipe(
      map((tests) => {
        return tests.map((test) => {
          const listItem = {
            ...test,
            id: test.id,
            faIcon: 'fa-solid fa-bullseye', // this.tests.TestIcons[test.type],
            url: `/test/${test.id}`,
            name: this.testService.TestNames[test.type],
            createdAt: test.createdAt,
          };
          return listItem;
        });
      }),
    );

    this.myVafTestsList$ = combineLatest([
      this.testService.currentClientMyVafTests$,
      this.myVafProtocolDefs$,
    ]).pipe(
      map(([tests]) => {
        return tests
          .map((item) => {
            const type = this.getMyVafProtocolTestType(item.protocolDefId);
            // item['icon-to-show'] =
            //   type === 'table' ? 'fas fa-dumbbell' : 'far fa-file-alt';
            // const faIcon =
            //   type === 'table' ? 'fas fa-dumbbell' : 'far fa-file-alt';
            item['icon-to-show'] = 'fa-solid fa-list-check';
            const faIcon = 'fa-solid fa-list-check';
            return {
              ...item,
              id: item.id,
              faIcon,
              url: `/test/${item.id}`,
              name: this.getMyVafProtocolTestName(item.protocolDefId),
              createdAt: item.createdAt,
            };
          })
          .sort((a, b) => {
            if (a.name === b.name) {
              return 0;
            } else if (a.name > b.name) {
              return 1;
            } else {
              return -1;
            }
          });
      }),
    );

    this.clientTrainingSessionsList$ = combineLatest([
      this.trainingSessionsService.currentClientTrainingSessions$,
      this.trainingSessionProtocolDefs$,
    ]).pipe(
      map(([sessions]) => {
        return sessions.map((session) => {
          return {
            ...session,
            faIcon: 'fas fa-dumbbell',
            id: session.id,
            name: this.getTrainingSessionProtocolName(
              session.trainingSessionProtocolDefId,
            ),
            url: `/client-training-session/${session.id}`,
            createdAt: session.createdAt,
          } satisfies ListItem<ClientTrainingSession>;
        });
      }),
    );

    this.clientCorrectiveSessionsList$ = combineLatest([
      this.correctiveSessionsService.currentClientCorrectiveSessions$,
      this.correctiveSessionProtocolDefs$,
    ]).pipe(
      map(([sessions]) => {
        return sessions.map((session) => {
          return {
            ...session,
            faIcon: 'fa-regular fa-life-ring',
            id: session.id,
            name: this.getCorrectiveSessionProtocolName(
              session.correctiveSessionProtocolDefId,
            ),
            url: `/client-corrective-session/${session.id}`,
            createdAt: session.createdAt,
          } satisfies ListItem<ClientCorrectiveSession>;
        });
      }),
    );

    this.trainingAvatarList$ =
      this.testService.currentClientTrainingAvatarRegistries$.pipe(
        map((trainingAvatarRegistries) => {
          return trainingAvatarRegistries.map((trainingAvatar) => {
            const listItem = {
              ...trainingAvatar,
              id: trainingAvatar.id,
              faIcon: 'fa-solid fa-bullseye', // TODO: change icon
              url: `/training-avatar/${trainingAvatar.id}`, // TODO: this will be an on click function or similar
              name: this.testService.TestNames[trainingAvatar.type],
              createdAt: trainingAvatar.createdAt,
            };
            return listItem;
          });
        }),
      );

    // Add the combined loading check after init()
    combineLatest([
      this.client$,
      this.clientTestsList$,
      this.myVafTestsList$,
      this.clientTrainingSessionsList$,
      this.clientCorrectiveSessionsList$,
      this.trainingAvatarList$,
      this.myVafProtocolDefs$,
      this.trainingSessionProtocolDefs$,
      this.correctiveSessionProtocolDefs$,
      // Convert promises to observables
      from(this.canI.canViewTests(AppConfig.app)),
      from(this.canI.canUseMyVaf(AppConfig.app)),
      from(this.canI.canViewTrainingSessions(AppConfig.app)),
      from(this.canI.canViewCorrectiveSessions(AppConfig.app)),
    ])
      .pipe(
        tap({
          next: ([
            client,
            tests,
            myVafTests,
            trainingSessions,
            correctiveSessions,
            trainingAvatars,
            myVafProtocols,
            trainingSessionProtocols,
            correctiveSessionProtocols,
            canViewTests,
            canUseMyVaf,
            canViewTrainingSessions,
            canViewCorrectiveSessions,
          ]) => {
            // All data has loaded successfully
            this.pageLoaded = 'idle';
            this.loading$.next(false);
          },
          error: (error) => {
            console.error('Error loading client data:', error);
            this.pageLoaded = 'error';
            this.loading$.next(false);
          },
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  // ---- TESTS FUNCTIONS ----
  openNewValorationMenu() {
    if (this.currentApp === 'rom') {
      this.createTest('rom');
      return;
    } else if (this.currentApp === 'raquis') {
      this.newValorationMenuOpen = true;
      this.overrideBackButton.override(() => {
        this.newValorationMenuOpen = false;
      });
    } else {
      this.newValorationMenuComponent.isOpen = true;
      this.overrideBackButton.override(() => {
        this.newValorationMenuComponent.isOpen = false;
      });
    }
  }

  closeNewValorationMenu() {
    history.back();
  }

  async createTest(typeS: string) {
    if (typeS === 'rom-short') {
      await sleep(100);
      this.openAvatarModal();
      return;
    }
    const type = typeS as TestType;
    const raquis =
      type === TestType.Sagital ||
      type === TestType.SagitalFlechas ||
      type === TestType.SagitalImg;
    if (
      !(await this.canI.requestCreateTest(
        this.testService.currentClientTestsCount,
        raquis,
      ))
    ) {
      return;
    }
    this.addingNewTest = true;
    const clientId = await safeToPromise(this.clientId$.pipe(take(1)));
    const result = await this.testService.createTest(clientId, type);
    if (!!result) {
      this.nav.goToTest(result.id);
    }
    this.addingNewTest = false;
  }

  deleteValorations(valorations: Map<string, Test>) {
    this.confirmActionService.openDialog({
      title: `¿Quieres borrar ${valorations.size} valoración/es?`,
      description:
        'Si continúas no podrás recuperar ninguna de estas valoraciones.',
      confirmButton: `Eliminar valoraciones`,
      confirmCallback: async (confirm) => {
        if (confirm) {
          await this.testService.deleteTests([...valorations.keys()]);
        }
      },
    });
  }

  async generateReport(items: Map<string, Test>) {
    if (this.generatingReport) {
      return;
    }
    this.generatingReport = true;
    this.actionInProgressService.component.show('Generando informe...');

    try {
      await this.reportsServices.generateReport([...items.values()]);
    } catch (e) {
      this.snackbarService.error('Error generando informe');
    } finally {
      this.actionInProgressService.component.hide();
      this.generatingReport = false;
    }
  }

  async generateReportForValorations(valorations: Map<string, Test>) {
    await this.generateReport(valorations);
  }

  async handleShowToClientTests(item: Test) {
    await this.testService.updateTest({
      ...item,
      showToClient: !item.showToClient,
    });
  }

  // ---- MY VAF PROTOCOLS FUNCTIONS ----
  getMyVafProtocolTestName(protocolId: string) {
    const result = this.myVafProtocolDefs.find(
      (protocol) => protocol.id === protocolId,
    );
    if (!result) {
      return 'Protocolo eliminado';
    } else {
      return result.name;
    }
  }

  getMyVafProtocolTestType(protocolId: string) {
    const result = this.myVafProtocolDefs.find(
      (protocol) => protocol.id === protocolId,
    );
    if (!result || !result.tests || result.tests.length === 0) {
      return 'Protocolo eliminado';
    } else {
      return result.tests[0].mode;
    }
  }

  getMyVafProtocolInitialsLocal(protocolId: string) {
    const result = this.myVafProtocolDefs.find(
      (protocol) => protocol.id === protocolId,
    );
    if (!result) {
      return 'PE';
    } else {
      return getProtocolInitials(result);
    }
  }

  openMyVafMenu() {
    if (!this.myVafMenuComponent) {
      console.warn('My VAF menu component not initialized');
      return;
    }

    this.myVafMenuComponent.isOpen = true;
    this.overrideBackButton.override(() => {
      if (this.myVafMenuComponent) {
        this.myVafMenuComponent.isOpen = false;
      }
    });
  }

  async createMyVafTest(protocolDef: MyVafProtocolDef) {
    const type = 'my-vaf' as TestType;
    this.addingNewTest = true;
    const clientId = await safeToPromise(this.clientId$.pipe(take(1)));
    const result = await this.testService.createMyVafTest(
      clientId,
      protocolDef,
    );
    if (!!result) {
      this.nav.goToTest(result.id);
    }
    this.addingNewTest = false;
  }

  deleteValorationsMyVaf(myVafTests: Map<string, Test>) {
    this.confirmActionService.openDialog({
      title: `¿Quieres borrar ${myVafTests.size} valoración/es?`,
      description:
        'Si continúas no podrás recuperar ninguna de estas valoraciones.',
      confirmButton: `Eliminar valoraciones`,
      confirmCallback: async (confirm) => {
        if (confirm) {
          await this.testService.deleteTests([...myVafTests.keys()]);
        }
      },
    });
  }

  async generateReportMyVaf(myVafTests: Map<string, Test>) {
    await this.generateReport(myVafTests);
  }

  async handleShowToClientMyVafTests(item: Test) {
    await this.testService.updateTest({
      ...item,
      showToClient: !item.showToClient,
    });
  }

  // ---- TRAINING SESSIONS FUNCTIONS ----
  getTrainingSessionProtocolName(protocolId: string) {
    const result = this.trainingSessionProtocolDefs.find(
      (protocol) => protocol.id === protocolId,
    );
    if (!result) {
      return 'Protocolo de sesión de entrenamiento eliminado';
    } else {
      return result.name;
    }
  }

  openTrainingSessionsMenu() {
    if (!this.trainingSessionsMenuComponent) {
      console.warn('Training sessions menu component not initialized');
      return;
    }

    this.trainingSessionsMenuComponent.isOpen = true;
    this.overrideBackButton.override(() => {
      if (this.trainingSessionsMenuComponent) {
        this.trainingSessionsMenuComponent.isOpen = false;
      }
    });
  }

  async createClientTrainingSession(
    trainingSessionProtocolDef: TrainingSessionProtocolDef,
  ) {
    this.addingNewTest = true;
    const clientId = await safeToPromise(this.clientId$.pipe(take(1)));
    const result =
      await this.trainingSessionsService.createClientTrainingSession(
        clientId,
        trainingSessionProtocolDef,
      );
    if (!!result) {
      this.nav.goToClientTrainingSession(result.id);
    }
    this.addingNewTest = false;
  }

  deleteClientTrainingSessions(
    clientTrainingSessions: Map<string, ClientTrainingSession>,
  ) {
    this.confirmActionService.openDialog({
      title: `¿Quieres borrar ${clientTrainingSessions.size} sesión/es de entrenamiento?`,
      description:
        'Si continúas no podrás recuperar ninguna de estas sesiones.',
      confirmButton: `Eliminar sesiones`,
      confirmCallback: async (confirm) => {
        if (confirm) {
          await this.trainingSessionsService.deleteClientTrainingSessions([
            ...clientTrainingSessions.keys(),
          ]);
        }
      },
    });
  }

  async generateReportClientTrainingSessions(
    clientTrainingSessions: Map<string, Test>, // actually, is a ClientTrainingSession not a Test, but is valid for this case to keep ts happy
  ) {
    await this.generateReport(clientTrainingSessions);
  }

  async handleShowToClientTrainingSessions(item: ClientTrainingSession) {
    await this.trainingSessionsService.updateClientTrainingSession({
      ...item,
      showToClient: !item.showToClient,
    });
  }

  // --- CORRECTIVE SESSIONS FUNCTIONS ---
  getCorrectiveSessionProtocolName(protocolId: string) {
    const result = this.correctiveSessionProtocolDefs.find(
      (protocol) => protocol.id === protocolId,
    );
    if (!result) {
      return 'Protocolo de sesión de ejercicios correctivos eliminado';
    } else {
      return result.name;
    }
  }

  openCorrectiveSessionsMenu() {
    if (!this.correctiveSessionMenuComponent) {
      console.warn('Corrective sessions menu component not initialized');
      return;
    }

    this.correctiveSessionMenuComponent.isOpen = true;
    this.overrideBackButton.override(() => {
      this.correctiveSessionMenuComponent.isOpen = false;
    });
  }

  async createClientCorrectiveSession(
    correctiveSessionProtocolDef: CorrectiveSessionProtocolDef,
  ) {
    this.addingNewTest = true;
    const clientId = await safeToPromise(this.clientId$.pipe(take(1)));
    const result =
      await this.correctiveSessionsService.createClientCorrectiveSession(
        clientId,
        correctiveSessionProtocolDef,
      );
    if (!!result) {
      this.nav.goToClientCorrectiveSession(result.id);
    }
    this.addingNewTest = false;
  }

  deleteClientCorrectiveSessions(
    correctiveSessions: Map<string, ClientTrainingSession>,
  ) {
    this.confirmActionService.openDialog({
      title: `¿Quieres borrar ${correctiveSessions.size} sesión/es de ejercicios correctivos?`,
      description:
        'Si continúas no podrás recuperar ninguna de estas sesiones.',
      confirmButton: `Eliminar sesiones`,
      confirmCallback: async (confirm) => {
        if (confirm) {
          await this.correctiveSessionsService.deleteClientCorrectiveSessions([
            ...correctiveSessions.keys(),
          ]);
        }
      },
    });
  }

  async generateReportClientCorrectiveSessions(
    correctiveSessions: Map<string, Test>, // actually, is a ClientCorrectiveSession not a Test, but is valid for this case to keep ts happy
  ) {
    await this.generateReport(correctiveSessions);
  }

  async handleShowToClientCorrectiveSessions(item: ClientCorrectiveSession) {
    await this.correctiveSessionsService.updateClientCorrectiveSession({
      ...item,
      showToClient: !item.showToClient,
    });
  }

  // --- TRAINING AVATAR FUNCTIONS ---
  deleteTrainingAvatars(trainingAvatars: Map<string, TrainingAvatar>) {
    this.confirmActionService.openDialog({
      title: `¿Quieres borrar ${trainingAvatars.size} registro/s?`,
      description: 'Si continúas no podrás recuperar estos registros.',
      confirmButton: `Eliminar`,
      confirmCallback: async (confirm) => {
        if (confirm) {
          await this.testService.deleteTrainingAvatars([
            ...trainingAvatars.keys(),
          ]);
        }
      },
    });
  }

  async generateReportTrainingAvatars(
    trainingAvatars: Map<string, TrainingAvatar>,
  ) {
    return console.error('generateReportTrainingAvatars not implemented');
    if (this.generatingReport) {
      return;
    }
    this.generatingReport = true;
    this.actionInProgressService.component.show('Generando informe...');
    // TODO: the logic to handle training avatars report is not done yet, continue from this point
    await this.reportsServices.generateReport([...trainingAvatars.values()]);
    this.actionInProgressService.component.hide();
    this.generatingReport = false;
  }

  async handleShowToClientTrainingAvatars(item: TrainingAvatar) {
    console.log('handleShowToClientTrainingAvatars not implemented');
  }

  // --- OTHER FUNCTIONS ---
  async startSubscription() {
    this.subscribeButtonLoading = true;
    await this.subscriptionsService.openCheckoutSession(
      SubPlanCodes.RaquisPro50,
    );
    this.subscribeButtonLoading = false;
  }

  async openUpgradeModal() {
    this.modalService.openModal('new-upgrade', {});
  }

  async editClient(data: any) {
    // console.log('Modal open');
    await this.modalService.openModal('create-edit-client', data);
    // console.log('Modal closed');
  }

  async openSideModal(modalId: ModalKey) {
    const client = await lastValueFrom(this.client$.pipe(take(1)));
    await this.modalService.openLateralModal(modalId, { client });
  }

  async openAvatarModal() {
    await this.modalService.openSideLeftModal('avatar');
  }

  async openTrainingAvatarModal() {
    if (!(await this.canI.requestUseTrainingAvatar())) {
      return;
    }
    await this.modalService.openLateralModal('training-avatar', undefined, {
      slideConfig: 'right-small',
    });
  }

  async createClientCorrectiveSessionFromSharedUrl() {
    this.creatingCorrectiveSessionFromUrl = true;
    await this.correctiveSessionsService.createClientCorrectiveSessionFromSharedUrl(
      this.correctiveSessionSharedUrl,
      this.currentClient,
    );
    this.creatingCorrectiveSessionFromUrl = false;
  }

  async createClientTrainingSessionFromSharedUrl() {
    this.creatingTrainingSessionFromUrl = true;
    await this.trainingSessionsService.createClientTrainingSessionFromSharedUrl(
      this.trainingSessionSharedUrl,
      this.currentClient,
    );
    this.creatingTrainingSessionFromUrl = false;
  }

  async createClientProtocolFromSharedUrl() {
    this.creatingProtocolFromUrl = true;
    await this.testService.createMyVafTestFromSharedUrl(
      this.protocolSharedUrl,
      this.currentClient,
    );
    this.creatingProtocolFromUrl = false;
  }

  // client level dropdown
  onOptionSelected(option: string) {
    console.log('Selected option:', option);
    // Handle the selected option
  }

  openLinkClientModal(client: Client) {
    this.modalService.openModal('link-client', { client });
  }
}
